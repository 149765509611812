
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { FilterMatchMode } from "primevue/api";
import { Certificate } from "@/types/entities/Certificate";

export default {
  data() {
    return {
      loading: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  },
  mounted() {
    this.loadRequiredData();
  },
  computed: {
    ...mapGetters({
      certificates: "certificates/certificates",
      hasAccess: "teamMembers/hasAccess",
      canDelete: "teamMembers/canDelete"
    })
  },
  methods: {
    async confirmDelete(certificate: Certificate) {
      this.$confirm.require({
        header: "Confirmation deletion",
        message: "Are you sure you want to delete the certificate?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await this.$store.dispatch("certificates/deleteCertificate", certificate);
            await this.loadRequiredData();
            useToast().success("Certificate deleted");
          } catch (err) {
            useToast().error("Could not delete Certificate: " + err);
          }
        }
      });
    },

    async loadRequiredData() {
      this.loading = true;
      await this.$store.dispatch("certificates/getCertificates");
      await this.$store.dispatch("teamMembers/getMyRole");
      this.loading = false;
    },

    onRowClicked({ data }) {
      this.$router.push({
        name: "certificate",
        params: { certificateId: data.id }
      });
    }
  }
};
