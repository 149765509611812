import apiClient from '@/services/axios'

export async function post(lessonId) {
  var response = await apiClient.post('/lessons/' + lessonId + '/duplicate')
  if (response) {
    return response.data
  }

  throw new Error('Unable to duplicate Lesson.')
}
