import * as apiGetInvitation from '@/api/invitations/getInvitation'
import * as apiAcceptInvitation from '@/api/invitations/acceptInvitation'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        invitations: {},
        acceptResults: {}
    }),
    mutations: {
        gotInvitation(state, invitation) {
            state.invitations[invitation.invitationId] = invitation
        },

        gotAcceptResult(state, acceptResult) {
            state.acceptResults[acceptResult.invitationId] = acceptResult
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getInvitation({ commit }, { invitationId }) {
            commit('isLoading', true)

            console.log('Getting Invitation:', invitationId);
        
            const result = await apiGetInvitation.get(invitationId)

            if (result) {
                result.invitationId = invitationId
                commit('gotInvitation', result)
            }
        
            commit('isLoading', false)
        },

        async acceptInvitation({ commit }, { invitationId }) {
            commit('isLoading', true)

            console.log('Accepting Invitation:', invitationId);
        
            const result = await apiAcceptInvitation.put(invitationId)

            if (result) {
                result.invitationId = invitationId
                commit('gotAcceptResult', result)
            }
        
            commit('isLoading', false)
        },
    },
    getters: {
        invitation: (state) => (invitationId) => {
            return state.invitations[invitationId]
        },

        acceptResult: (state) => (invitationId) => {
            return state.acceptResults[invitationId]
        }
    }
};