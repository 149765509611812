
import IdamSegmentator from "../../components/Custom/IdamSegmentator/IdamSegmentator.vue";
import CourseLessons from "./components/CourseLessons.vue";

import { useToast } from "vue-toastification";
import { Folder, FolderTreeViewModel } from "@/types/entities/Folder";
import { mapGetters } from "vuex";

export default {
  components: {
    IdamSegmentator,
    CourseLessons
  },
  data() {
    return {
      loading: true,
      course: undefined,
      courseId: undefined,

      saveLoading: false
    };
  },
  async mounted() {
    this.courseId = this.$route.params.courseId;
    await this.loadRequiredData();
  },
  computed: {
    ...mapGetters({
      folders: "folders/folders",
      certificates: "certificates/certificates",
      positions: "userQueries/positions",
      userQueryInfo: "userQueries/userQueryInfo"
    }),
    folderNode() {
      return this.course.folderId ? { [this.course.folderId]: true } : null;
    }
  },
  methods: {
    async loadRequiredData() {
      this.loading = true;

      // Not awaiting as we don't need to
      this.$store.dispatch("folders/getFolders");
      this.$store.dispatch("certificates/getCertificates");
      this.$store.dispatch("lessons/getLessons");

      this.course = await this.$store.dispatch("courses/getCourse", {
        courseId: this.courseId
      });
      console.log("COURSE:", this.course);

      this.loading = false;
    },

    async saveCourse() {
      try {
        this.saveLoading = true;

        await this.$store.dispatch("courses/saveCourse", this.course);
        useToast().success("Course saved.");
      } catch (err) {
        useToast().error(err.toString());
      } finally {
        this.saveLoading = false;
      }
    },
    removeTag(item) {
      console.log("ITEM:", item);
      this.course.tags.splice(this.course.tags.indexOf(item), 1);
    },

    onNodeSelect(e) {
      this.course.folderId = Object.keys(e)[0];
    },

    // Start with null as the parentFolderId with null is root, and work our way down
    buildFolderNodeTree(parentFolderId: string | null = null): FolderTreeViewModel[] {
      return this.folders
        .filter((f: Folder) => f.parentFolderId === parentFolderId)
        .map((f: Folder) => {
          return {
            key: f.id,
            label: f.title,
            icon: "fa fa-folder",
            children: this.buildFolderNodeTree(f.id)
          };
        });
    }
  }
};
