<template>
  <div v-if="page.templateInput" class="page pb-4">
    <h2>{{ page.templateInput["leapform:title"] }}</h2>
    <p class="mb-4" style="white-space: pre">
      {{ page.templateInput["leapform:description"] }}
    </p>

    <div style="display: flex">
      <Form
        v-if="page.nodes"
        class="left-col"
        ref="pageForm"
        :id="formId"
        :elements="page.nodes"
        :form-data="formData"
        :isPassFail="isPassFail"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { ServiceBus } from "@/leapform/LeapFormServiceBus";

import Form from "@/components/Form";

export default {
  name: "LessonPage",
  components: {
    Form,
  },
  props: ["lessonId", "pageKey"],
  setup(props) {
    const isPassFail = ref(false);
    const pageForm = ref(null);

    const formId = ref(null);

    const loading = ref(true);
    const page = ref({
      title: "",
      description: "",
    });

    const video = ref({
      title: "",
      description: "",
    });

    const formData = ref({});

    const store = useStore();
    const router = useRouter();

    async function loadRequiredData() {
      loading.value = true;

      console.log("Ensuring Lesson:", props.lessonId);
      const loadedLesson = await store.dispatch("lessons/getLesson", {
        lessonId: props.lessonId,
      });

      let loadedPage = null;
      for (const category in loadedLesson.compiledLesson.categories) {
        const loadedCategoryPage = loadedLesson.compiledLesson.categories[
          category
        ].pages.find((page) => page.slug === props.pageKey);
        if (loadedCategoryPage) {
          loadedPage = loadedCategoryPage;
        }
      }

      console.log("Loaded page:", loadedPage);
      isPassFail.value = loadedLesson.isPassFail;
      page.value = JSON.parse(JSON.stringify(loadedPage));

      formId.value = /*props.lessonId + ':'+ */ page.value.identifier;

      video.value = {
        title: loadedPage.templateInput["videoTitle"],
        description: loadedPage.templateInput["videoDescription"],
        url: loadedPage.templateInput["videoUrl"],
      };

      const loadedFormData = store.getters["lesson/pageForm"](
        props.lessonId,
        page.value.identifier
      );
      if (loadedFormData !== null) {
        formData.value = loadedFormData;

        await store.dispatch("forms/cacheForm", {
          formId: formId.value,
          data: loadedFormData,
        });
      }

      ServiceBus.instance().on("end-event", async (event) => {
        console.log("GOT END EVENT:", event);

        await store.dispatch("lesson/complete", {
          lessonId: props.lessonId,
        });

        router.push({ name: "campus", params: event.payload });
      });

      ServiceBus.instance().on("form:" + formId.value, async (/*event*/) => {
        if (pageForm.value === null) {
          return;
        }

        const formData = store.getters["forms/form"](page.value.identifier);

        const command = {
          lessonId: props.lessonId,
          pageId: page.value.identifier,
          data: formData,
        };

        await store.dispatch("lesson/savePage", command);
      });

      loading.value = false;
    }

    loadRequiredData();

    /*function onFormInstantiated() {
      if (formData.value) {
        pageForm.value.loadModel(formData.value)
      }
    }*/

    return {
      formId,

      pageForm,
      formData,

      loading,

      page,
      video,

      isPassFail,

      //onFormInstantiated
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.left-col {
  flex: 1;
}

.right-col {
  width: 350px;
  margin-left: 15px;
}
</style>
