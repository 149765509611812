import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';

import {
  is
} from 'bpmn-js/lib/util/ModelUtil';

// import { useStore } from 'vuex'
import store from '@/store'

export default function (group, element, translate) {

  // Only return an entry, if the currently selected
  // element is a start event.

  store.dispatch('bpmn/refreshTaskOptions');

  if (is(element, 'bpmn:StartEvent')) {
    group.entries.push(entryFactory.textField(translate, {
      id: 'spell',
      description: 'Custom Field Description',
      label: 'Custom Field',
      modelProperty: 'spell'
    }));
  }

  if (is(element, 'bpmn:Task')) {
    var taskTypeSelectBox = entryFactory.selectBox(translate, {
      id: 'taskType',
      description: '',
      label: 'Task Type',
      selectOptions: store.getters['bpmn/taskOptions'],
      modelProperty: 'taskType'
    });

    group.entries.push(taskTypeSelectBox);
  }
}