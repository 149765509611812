<template>
  <div style="display: flex; flex: 1">
    <div class="flex flex-1 flex-col">
      <div v-if="loading" class="w-full h-80 flex justify-center items-center">
        <Loading text="Fetching lesson" />
      </div>
      <div v-else>
        <div class="flex justify-between pt-4 px-8">
          <div>
            <div>
              <p style="margin-bottom: 0.2rem; text-transform: uppercase">
                Lesson
              </p>
              <h2 class="text-4xl mb-3 font-bold tracking-tight text-gray-900">
                <span class="fa fa-graduation-cap"></span>
                {{ lesson?.title }}
              </h2>
            </div>
            <p class="mb-4">
              Create or edit a lesson lesson using BPMN 2.0 notation.
            </p>
          </div>
          <div>
            <div class="flex gap-2">
              <Button
                @click="saveLesson"
                icon="fa-solid fa-floppy-disk"
                label="Save"
                :loading="saveLoading"
              />
              <Button
                @click="previewLesson"
                icon="fa-solid fa-eye"
                severity="info"
                outlined
                label="Preview"
              />
            </div>
          </div>
        </div>

        <!-- Tab panes -->
        <TabView v-if="!loading" v-model:activeIndex="activeIndex">
          <TabPanel>
            <template #header>
              <i class="fa fa-cog mr-2"></i>
              <span>Lesson setup</span>
            </template>
            <div v-if="lesson" class="p-4">
              <!-- <div class="row mb-3">
                <label class="col-sm-4 col-form-label">Is active</label>
                <div class="col-sm-8 flex items-center gap-2">
                  <InputSwitch v-model="lesson.isActive" />
                  <div>
                    {{ $filters.boolYesNo(lesson.isActive) }}
                  </div>
                </div>
              </div> -->
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">Is pass fail</label>
                <div class="col-sm-8 flex items-center gap-2">
                  <InputSwitch v-model="lesson.isPassFail" />
                  <div>
                    {{ $filters.boolYesNo(lesson.isPassFail) }}
                  </div>
                </div>
              </div>
              <div class="row mb-3" v-if="lesson.isPassFail">
                <label class="col-sm-4 col-form-label">Min. pass score %</label>
                <div class="col-sm-8 flex items-center gap-2">
                  <InputNumber
                    v-model="lesson.minPassingScore"
                    :min="0"
                    :max="100"
                    :maxFractionDigits="0"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">Tags</label>
                <div class="col-sm-8">
                  <Chips
                    v-model="lesson.tags"
                    :allowDuplicate="false"
                    placeholder="Enter tags"
                    class="!block"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">Title</label>
                <div class="col-sm-8">
                  <InputText
                    v-model="lesson.title"
                    class="w-full"
                    placeholder="Title"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <label class="col-sm-4 col-form-label">Description</label>
                <div class="col-sm-8">
                  <Textarea
                    v-model="lesson.description"
                    class="w-full"
                    autoResize
                    placeholder="Description"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">Image URL</label>
                <div class="col-sm-8">
                  <InputText
                    v-model="lesson.imageUrl"
                    class="w-full"
                    placeholder="Image url for the course"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label"> Estimated time </label>
                <div class="col-sm-8">
                  <InputNumber
                    v-model="lesson.estimatedTime"
                    class="w-full"
                    suffix=" minutes"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">End Button title</label>
                <div class="col-sm-8">
                  <InputText
                    v-model="lesson.endButtonTitle"
                    class="w-full"
                    placeholder="Text displayed in the complete button"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-4 col-form-label">End message</label>
                <div class="col-sm-8">
                  <Textarea
                    v-model="lesson.endMessage"
                    class="w-full"
                    autoResize
                    placeholder="Message displayed upon completion"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="fa-solid fa-diagram-project mr-2"></i>
              <span>Lesson structure</span>
            </template>

            <div id="bmpn-container"></div>
          </TabPanel>
        </TabView>
      </div>
    </div>

    <div class="flex" :class="{ hidden: activeIndex == 0 }">
      <div id="properties-panel" class="right properties-panel-parent"></div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { toRaw } from "vue";

// Bpmn section
import newDiagram from "!raw-loader!./new-diagram.bpmn";
import BpmnModeler from "bpmn-js/lib/Modeler";
import PropertiesPanelModule from "bpmn-js-properties-panel";
import CampusOnlinePropertiesProviderModule from "./bpmn/providers/campusOnline";
import CamundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import resizeTask from "bpmn-js-task-resize/lib";
// import demo from "!raw-loader!./demo.bpmn";
// import BpmnJS from "bpmn-js";
// import CamundaPropertiesProviderModule from "bpmn-js-properties-panel/lib/provider/camunda";

export default {
  data() {
    return {
      loading: true,
      lesson: undefined,
      lessonId: undefined,

      downloadHref: "",
      downloadName: "",

      activeIndex: 0,
      modeler: null,

      saveLoading: false,
    };
  },
  async mounted() {
    this.lessonId = this.$route.params.lessonId;
    await this.loadRequiredData();

    await this.$store.dispatch("bpmn/ensureBpmnTasks");
    const templates = this.$store.getters["bpmn/lessonTemplates"];

    const modeler = new BpmnModeler({
      // container: "#bmpn-container",
      propertiesPanel: {
        parent: "#properties-panel",
      },
      additionalModules: [
        PropertiesPanelModule,
        // CamundaPropertiesProviderModule,
        CampusOnlinePropertiesProviderModule,
        resizeTask,
      ],
      taskResizingEnabled: true,
      elementTemplates: templates,
      moddleExtensions: {
        camunda: CamundaModdleDescriptor,
      },
    });

    modeler.on("elementTemplates.errors", function (event) {
      console.log("Template load errors", event.errors);
    });

    modeler.attachTo("#bmpn-container");

    console.log("Importing XML");
    console.log(this.lesson);

    if (!this.lesson.xml) {
      console.log("XML not defined. Importing New Diagram template.");
      modeler.importXML(newDiagram);
    } else {
      modeler.importXML(this.lesson.xml, (err) => {
        if (err) {
          console.error("Error importing XML: ", err);
        } else {
          console.log("XML imported.");
        }
      });
    }

    this.modeler = modeler;
  },
  unmounted() {
    this.modeler.destroy();
  },
  methods: {
    async loadRequiredData() {
      this.loading = true;

      this.lesson = await this.$store.dispatch("lessons/getLesson", {
        lessonId: this.lessonId,
      });
      console.log("LESSON: ", this.lesson);

      this.loading = false;
    },

    async saveLesson() {
      try {
        this.saveLoading = true;
        // Unwrap the modeler as it does not work as a proxy
        const modeler = toRaw(this.modeler);

        const { xml } = await modeler.saveXML({
          format: true,
        });

        this.lesson.xml = xml;
        await this.$store.dispatch("lessons/saveLesson", this.lesson);

        this.setEncoded(this.lesson.id + ".bpmn", xml);

        useToast().success("Lesson saved.");
      } catch (err) {
        console.error("Error while saving XML: ", err);
        this.setEncoded(this.lesson.id + ".bpmn", null);

        useToast().error(err.toString());
      } finally {
        this.saveLoading = false;
      }
    },

    removeTag(item) {
      console.log("ITEM:", item);
      this.lesson.tags.splice(this.lesson.tags.indexOf(item), 1);
    },

    previewLesson() {
      try {
        this.$router.push({
          name: "load-preview-lesson-page",
          params: { lessonId: this.lesson.id },
        });
      } catch (err) {
        console.error("Error happened previewing XML: ", err);
      }
    },

    setEncoded(name, data) {
      var encodedData = encodeURIComponent(data);

      if (data) {
        this.downloadName = name;
        this.downloadHref =
          "data:application/bpmn20-xml;charset=UTF-8," + encodedData;
      } else {
        this.downloadName = "";
        this.downloadHref = "";
      }
    },
  },
};
</script>

<style scoped>
#bmpn-container {
  background-color: #fff;

  display: flex;
  flex: 1;
  flex-direction: column;
  height: 800px;
}

.right {
  width: 350px;
}
</style>

<style>
.properties-panel-parent {
  display: flex;
  background-color: #f8f8f8;
  border-left: 1px solid #ccc;
}
.bpp-properties-panel {
  flex: 1;
}
</style>