import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center gap-3" }
const _hoisted_2 = { class: "text-gray-400 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProgressSpinner, {
      style: {"width":"50px","height":"50px"},
      strokeWidth: "8",
      fill: "var(--surface-ground)",
      animationDuration: "1s",
      "aria-label": "Custom ProgressSpinner"
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.text), 1)
  ]))
}