import { createStore } from "vuex"

import accounts from './modules/accounts'

import bpmn from './modules/bpmn'

import certificate from './modules/certificate'
import certificates from './modules/certificates'

import invitations from './modules/invitations'

import courses from './modules/courses'

import lessons from './modules/lessons'
import lesson from './modules/lesson'

import forms from './modules/forms'

import folders from './modules/folders'

import teamMembers from './modules/teamMembers'

import userQueries from "./modules/userQueries";

const store = createStore({
   state: {
      name: "CampusOnline"
   },
   modules: {
      accounts,

      bpmn,

      certificate,
      certificates,

      invitations,

      teamMembers,

      courses,

      // Modules needed to preview form
      forms,
      lesson,

      lessons,
      // lessonTemplates,

      folders,

      userQueries,
   }
})

export default store