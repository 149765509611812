<template>
  <div class="page">
    <div class="flex justify-between">
      <div>
        <h2 class="text-4xl mb-3 font-bold tracking-tight text-gray-900">
          <span class="fa fa-cog"></span>
          Settings
        </h2>
        <p class="mb-2 leading-6">
          Manage Business settings and REKOM Learning Online products for your
          Dashboard.
        </p>
      </div>
    </div>

    <Divider />

    <div class="page-container">
      <table class="links">
        <tr>
          <td>
            <h2 class="mt-2 text-lg font-bold">Your business</h2>
            <router-link to="/settings/account" class="disabled"
              >Account details</router-link
            >
            <router-link to="/settings/business" class="disabled"
              >Business details</router-link
            >
            <router-link to="/settings/bank" class="disabled"
              >Bank accounts and scheduling</router-link
            >
            <router-link to="/settings/tax" class="disabled"
              >Tax details</router-link
            >
            <router-link to="/settings/branding" class="disabled"
              >Branding</router-link
            >
            <router-link to="/settings/emails" class="disabled"
              >Emails</router-link
            >
          </td>

          <td>
            <h2 class="mt-2 text-lg font-bold">Team and security</h2>
            <router-link to="/settings/team" class="underline"
              >Team</router-link
            >
            <router-link to="/settings/security-history" class="disabled"
              >Security history</router-link
            >
            <router-link to="/account/applications" class="disabled"
              >Authorized applications</router-link
            >
          </td>

          <td>
            <h2 class="mt-2 text-lg font-bold">Reporting and documents</h2>
            <router-link to="/settings/documents" class="disabled"
              >Documents</router-link
            >
            <router-link to="/settings/legacy-exports" class="disabled"
              >Legacy exports</router-link
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
/*
Business settings

Your business

Account details
Business details
Bank accounts and scheduling
Tax details
Branding
Emails

Team and security

Team
Security history
Authorized applications

Reporting and documents

Documents
Legacy exports

Stripe beta programs

Early access features
*/
export default {
  name: "Settings",
  components: {},
  props: {},
  mounted: function () {
    console.log("Settings: mounted");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

table.links {
  width: 100%;
}

table.links td {
  vertical-align: top;
}

table.links a {
  display: block;
}
</style>
