
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { Course } from "../../types/entities/Course";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      loading: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
    this.loadRequiredData();
  },
  computed: {
    ...mapGetters({
      courses: "courses/courses",
      hasAccess: "teamMembers/hasAccess",
      canDelete: "teamMembers/canDelete",
    }),
  },
  methods: {
    async confirmDuplicate(course: Course) {
      this.$confirm.require({
        header: "Confirmation duplication",
        message: "Are you sure you want to duplicate the course?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await this.$store.dispatch("courses/duplicateCourse", course);
            await this.loadRequiredData();
            useToast().success("Course duplicated.");
          } catch (err) {
            useToast().error("Could not duplicate course: " + err);
          }
        },
      });
    },

    async confirmDelete(course: Course) {
      this.$confirm.require({
        header: "Confirmation deletion",
        message: "Are you sure you want to delete the course?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await this.$store.dispatch("courses/deleteCourse", course);
            await this.loadRequiredData();
            useToast().success("Course deleted");
          } catch (err) {
            useToast().error("Could not delete Course: " + err);
          }
        },
      });
    },

    async loadRequiredData() {
      this.loading = true;
      await this.$store.dispatch("courses/getCourses");
      await this.$store.dispatch("teamMembers/getMyRole");
      this.loading = false;
    },
    onRowClicked({ data }) {
      this.$router.push({
        name: "edit-course",
        params: { courseId: data.id },
      });
    },
  },
};
