import apiClient from '@/services/axios'

export async function post(documentTemplateId, payload) {
  var response = await apiClient({
    method: 'post',
    url: '/certificates/' + documentTemplateId + '/pdf',
    data: payload,
    responseType: 'blob'
  })

  if (response) {
    return response.data
  }

  throw new Error('Unable to export Document Template to PDF.')
}