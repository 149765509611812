<template>
  <treeselect
    v-if="regions"
    ref="treeselect"
    v-model="internalValue"
    :disabled="deactivated"
    :options="selectableRegions"
    :show-count="true"
    showCountOf="LEAF_DESCENDANTS"
    :placeholder="placeholder"
    :disable-branch-nodes="true"
    :flatten-search-results="true"
    value-consists-of="LEAF_PRIORITY"
  >
  </treeselect>
</template>

<script>
import Treeselect from "vue3-treeselect";

import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { Treeselect },
  props: {
    modelValue: {
      Type: Object | Array | null,
      default: null,
    },
    placeholder: {
      Type: String | undefined,
      default: "Select a region",
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      regions: "userQueries/regions",
    }),
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
    selectableRegions() {
      return _.chain(this.regions)
        .groupBy((e) => (e.group != "" ? e.group : "No country")) // Group by country
        .map((regions, country) => {
          return {
            id: country,
            label: regions[0].group,
            children: regions.map((region) => {
              return {
                id: region.id,
                label: region.name,
              };
            }),
          };
        })
        .value();
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value {
  max-height: 180px;
  overflow-y: auto;
}
</style>