<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <h1>
            <a href="/dashboard" class="logo">REKOM Learning Online<span>.</span></a>
          </h1>
        </li>
      </ul>
      <!--
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Disabled</a>
                </li>
            </ul>
            -->

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <div class="dropdown">
            <a
              class="profile nav-link dropdown-toggle"
              href="#"
              id="dropdownMenuButton1"
              role="button"
              @click="$refs.op.toggle"
            >
              {{ user.name }}
              <div
                style="margin-left: 8px"
                class="vs-avatar-content vs-change-color-badge"
              >
                <div class="vs-avatar">
                  <img :src="user.picture" alt="" />
                </div>
              </div>
            </a>

            <OverlayPanel ref="op">
              <div class="w-52">
                <div style="text-align: center">
                  <h3 class="section-subheader">Hi {{ user.firstName }}</h3>
                </div>
                <div
                  class="mt-2"
                  style="display: flex; justify-content: center"
                >
                  <div class="large vs-avatar-content vs-change-color-badge">
                    <div class="vs-avatar">
                      <img :src="user.picture" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  class="mt-3"
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                  "
                >
                  <!-- <router-link to="settings"> -->
                  <div style="text-align: center">
                    <i class="dropdown-icon fas fa-user-circle"></i>
                    <p style="margin-bottom: 0px">Account</p>
                  </div>
                  <!-- </router-link> -->
                  <div class="dropdown-button" style="text-align: center">
                    <i class="dropdown-icon fas fa-question-circle"></i>
                    <p style="margin-bottom: 0px">Support</p>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    @click="logout"
                    class="btn btn-outline-warning btn-block"
                  >
                    Log out
                  </button>
                </div>
              </div>
            </OverlayPanel>
            <!--
                        
                        <ul class="profile dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#">Indstillinger</a></li>
                            <div class="dropdown-divider"></div>
                            <li><a class="dropdown-item" href="#">Log ud</a></li>
                        </ul>
                        -->
          </div>
          <!--
                    <div class="dropdown">
                        <a class="profile nav-link dropdown-toggle" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Mikkel 
                            <div style="margin-left: 8px;" class="vs-avatar-content vs-change-color-badge">
                                <div class="vs-avatar">
                                <img src="@/assets/kh-sm.jpg" alt="">
                                </div>
                            </div>
                        </a>
                        <ul class="profile dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#">Indstillinger</a></li>
                            <div class="dropdown-divider"></div>
                            <li><a class="dropdown-item" href="#">Log ud</a></li>
                        </ul>
                    </div>
                    -->
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
/*import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
*/

import { authPlugin as $auth } from "@/auth";

export default {
  name: "NavBar",
  components: {},
  props: {},
  setup() {
    const user = ref($auth.user);
    return {
      user,
    };
  },
  methods: {
    logout() {
      const returnUrl = window.location.origin;

      $auth.logout({
        returnTo: returnUrl,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  background-color: #fff;
  margin: 0;
  padding: 0px 30px;
  box-shadow: none;
  border-bottom: 1px solid #e6e1ec;
}
.profile.dropdown-toggle {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #2a2371;
}
.profile.dropdown-toggle::after {
  color: #2a2371;
}
/*
.profile.dropdown-toggle::after {
    display: none;
    margin: 0;
    padding: 0;
}
*/

.profile.dropdown-menu {
  left: -180px;
  padding: 30px 20px;
  width: 300px;

  border: none;
  border-radius: 15px;
  box-shadow: 0 1px 2.2px rgb(0 0 0 / 2%), 0 2.3px 5.3px rgb(0 0 0 / 3%),
    0 4.4px 10px rgb(0 0 0 / 4%), 0 7.8px 17.9px rgb(0 0 0 / 4%),
    0 14.6px 33.4px rgb(0 0 0 / 5%), 0 35px 80px rgb(0 0 0 / 7%);
}

.large.vs-avatar-content {
  width: 70px;
  height: 70px;
}

.dropdown-icon {
  font-size: 32px;
  color: #393c7f;
}
</style>
