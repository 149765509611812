<template>
  <nav id="sidebar" :class="{ active: !isSidebarActive }">
    <div class="custom-menu">
      <button
        type="button"
        id="sidebarCollapse"
        @click="toggleSidebar()"
        class="btn btn-primary"
      >
        <i class="fa fa-bars"></i>
        <span class="sr-only">Toggle Menu</span>
      </button>
    </div>
    <div v-if="!loading" class="p-4">
      <!--  Disabled -->
      <div v-if="activeUserAccount && false" class="dropdown mb-4">
        <button
          class="btn btn-block btn-light dropdown-toggle user-account-dropdown"
          type="button"
          id="userAccounts"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!-- <img src="https://rekomfltstoragedev.blob.core.windows.net/static/rekom-logo.png" style="border-radius:50%;max-height:40px;max-width:40px;"/> -->
          <avatar
            v-if="activeUserAccount"
            :username="activeUserAccount.title"
            lighten="0"
            size="40"
            inline="true"
            :src="
              'https://legalmatestoragedev.blob.core.windows.net/static-files/accounts/' +
              activeUserAccount.accountId +
              '.png'
            "
          >
          </avatar>
          <span style="padding-left: 5px"> {{ activeUserAccount.title }}</span>
        </button>

        <ul class="dropdown-menu" aria-labelledby="userAccounts">
          <li v-for="userAccount in userAccounts" :key="userAccount.accountId">
            <button @click="switchAccount(userAccount)" class="dropdown-item">
              <avatar
                :username="userAccount.title"
                lighten="0"
                size="40"
                inline="true"
                :src="
                  'https://legalmatestoragedev.blob.core.windows.net/static-files/accounts/' +
                  userAccount.accountId +
                  '.png'
                "
              >
              </avatar>
              <span style="padding-left: 5px"> {{ userAccount.title }}</span>
            </button>
          </li>

          <li>
            <a class="dropdown-item" href="#">
              <span class="fa fa-plus-circle add-account-icon"></span>
              <span style="padding-left: 5px"> New account</span>
            </a>
          </li>
        </ul>
      </div>

      <ul class="list-unstyled components mb-5 leapform-sidebar-options">
        <li v-for="option in config.options" :key="option.title">
          <router-link
            :to="option.slug"
            :class="option.class"
            v-if="!option.role || myRole === option.role"
          >
            <span class="fa" :class="option.icon"></span>
            {{ option.title }}
          </router-link>
        </li>
      </ul>

      <!--
        <div class="mb-5">
          <h3 class="h6 mb-3">Subscribe for newsletter</h3>
          <form action="#" class="subscribe-form">
            <div class="form-group d-flex">
              <div class="icon"><span class="icon-paper-plane"></span></div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Email Address"
              />
            </div>
          </form>
        </div>
        -->
      <div class="footer"></div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import Avatar from "@/components/VueAvatar";

export default {
  name: "MainSidebar",
  props: [],
  components: {
    Avatar,
  },
  setup() {
    const loading = ref(true);

    const isSidebarActive = ref(true);

    const config = ref({
      options: [
        {
          title: "Dashboard",
          icon: "fa-info-circle",
          slug: "/dashboard",
        } /*
            {
                title: 'Forms',
                icon: 'fa-wpforms',
                slug: '/forms'
            },*/,
        // {
        //   title: "Content",
        //   icon: "fa-photo-video",
        //   slug: "/content",
        // },

        {
          title: "Folders",
          icon: "fa-folder",
          slug: "/folders",
        },
        {
          title: "Courses",
          icon: "fa-book",
          slug: "/courses",
        },
        {
          title: "Lessons",
          icon: "fa-graduation-cap",
          slug: "/lessons",
        },
        {
          title: "Certificates",
          icon: "fa-award",
          slug: "/certificates",
          role: "SuperAdministrator",
        },
        // {
        //     title: 'Workflows',
        //     icon: 'fa-project-diagram',
        //     slug: '/workflows'
        // },
        // {
        //     title: 'Workspaces',
        //     icon: 'fa-archive',
        //     slug: '/workspaces'
        // },
        // {
        //   title: "Developers",
        //   icon: "fa-code",
        //   slug: "/developers",
        //   class: "margin-up",
        // },
        {
          title: "Settings",
          icon: "fa-cog",
          slug: "/settings",
          class: "margin-up",
        },
      ],
    });

    const userAccounts = ref([]);
    const activeUserAccount = ref(undefined);

    const store = useStore();
    const myRole = ref(null);

    async function loadRequiredData() {
      loading.value = true;

      userAccounts.value = store.getters["accounts/userAccounts"];
      activeUserAccount.value = store.getters["accounts/activeUserAccount"];

      if (myRole.value === null) {
        myRole.value = await store.dispatch("teamMembers/getMyRole");
      }

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      loading,

      isSidebarActive,

      config,

      userAccounts,
      activeUserAccount,
      myRole,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },

    async switchAccount(account) {
      console.log(account);
      await this.$store.dispatch("accounts/setActiveUserAccount", {
        accountId: account.accountId,
      });

      this.activeUserAccount =
        this.$store.getters["accounts/activeUserAccount"];

      this.$router.go();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#sidebar ul.leapform-sidebar-options li a {
  display: flex;
  align-items: center;
}

#sidebar ul li a.router-link-active {
  @apply font-bold;
  color: #2a2371;
}

#sidebar ul li a {
  padding: 4px 0;
  font-size: 14px;
}

#sidebar ul li a .fa {
  width: 32px;
}

#sidebar ul li a.dropdown-item {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  padding-left: 4px;
}

.margin-up {
  margin-top: 14px;
}

.dropdown-toggle {
  text-align: left;
  padding-left: 0;
}

.dropdown-menu {
  padding: 0px;
  min-width: 220px;
}
.dropdown-toggle::after {
  right: 10px;
}
.dropdown-menu .dropdown-item {
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.add-account-icon {
  width: initial !important;
  font-size: 18px;
  padding-left: 11px;
  padding-right: 11px;
}

.user-account-dropdown {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}
</style>
