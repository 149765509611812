import * as apiGetCourse from '@/api/courses/getCourse'
import * as apiGetCourses from '@/api/courses/getCourses'
import * as apiAddCourse from '@/api/courses/addCourse'
import * as apiUpdateCourse from '@/api/courses/updateCourse'
import * as apiDeleteCourse from '@/api/courses/deleteCourse'
import * as apiDuplicateCourse from '@/api/courses/duplicateCourse'
import * as apiRearrangeCourses from '@/api/courses/rearrangeCourses'
import { Course, RearrangeCoursesDto } from '@/types/entities/Course'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        courses: [] as Course[],
    }),
    mutations: {
        gotCourses(state, courses: Course[]) {
            state.courses = courses
        },
    },
    actions: {
        async getCourses({ commit }) {
            const result = await apiGetCourses.get();

            if (result) {
                commit('gotCourses', result)
            }
        },

        async getCourse({ commit }, { courseId }): Promise<Course> {
            return await apiGetCourse.get(courseId)
        },

        async add({ commit }, { title }) {
            const payload = {
                title: title
            };

            const result = await apiAddCourse.post(payload)
            return result;
        },

        async saveCourse({ commit }, course: Course) {
            await apiUpdateCourse.put(course.id, course)
        },

        async deleteCourse({ commit, dispatch }, course: Course) {
            await apiDeleteCourse.del(course.id)
        },

        async duplicateCourse({ dispatch }, course: Course) {
            return await apiDuplicateCourse.post(course.id)
        },

        async rearrange({ dispatch }, dto: RearrangeCoursesDto) {
            await apiRearrangeCourses.post(dto)
        }
    },
    getters: {
        courses: (state) => state.courses,
    }
};