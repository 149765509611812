import apiClient from '@/services/axios'

export async function get() {
    var response = await apiClient.get(process.env.VUE_APP_LIFE_ADMIN_API_BASE_URL + `/UserQuery`)
    if (response) {
        return response.data
    }

    throw new Error('Unable to get user query info')
}
