<template>
  <div class="page">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      "
    >
      <div class="d-flex justify-content-center">
        <div
          class="spinner-grow"
          style="width: 5rem; height: 5rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "LoadingLessonPage",
  components: {},
  props: ["lessonId"],
  setup(props) {
    const loading = ref(true);

    const store = useStore();
    const router = useRouter();

    async function loadRequiredData() {
      loading.value = true;

      const loadedLesson = await store.dispatch("lessons/getLesson", {
        lessonId: props.lessonId,
      });

      console.log("Loaded lesson with ID:", props.lessonId, loadedLesson);

      const firstLessonCategory = loadedLesson.compiledLesson.categories[0];
      const firstPageSlug = firstLessonCategory.pages[0].slug;

      console.log("First page slug:", firstPageSlug);

      router.push({
        name: "preview-lesson-page",
        params: { lessonId: props.lessonId, pageKey: firstPageSlug },
      });

      loading.value = false;
    }

    loadRequiredData();

    return {
      loading,
    };
  },

  /*
  created() {
    const store = useStore();
    const router = useRouter();

    this.unwatch = store.watch(
      (state, getters) => getters['lessonTemplates/lesson'](this.lessonId),
      (activeLesson) => {

        const firstLessonCategory = activeLesson.categories[0];

        console.log(firstLessonCategory)

        router.push({ path: firstLessonCategory.defaultSlug })
      },
    );
  },
  beforeUnmount() {
    this.unwatch();
  },
  methods: {
  }
  */
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
