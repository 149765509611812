<template>
  <div class="page" style="display: flex; flex: 1; flex-direction: column">
    <div style="display: flex">
      <div>
        <div>
          <p style="margin-bottom: 0.2rem; text-transform: uppercase">
            Preview Lesson
          </p>
          <h2 v-if="lesson">{{ lesson.title }}</h2>
        </div>
        <p class="mb-4">Preview a lesson while developing the module flow.</p>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        "
      >
        <div style="display: flex; align-items: center">
          <button
            @click="clearLesson"
            type="button"
            class="ml-2 btn btn-primary"
          >
            Clear
          </button>
          <button
            @click="editLesson"
            type="button"
            class="ml-2 btn btn-primary"
          >
            Edit Module
          </button>
        </div>
      </div>
    </div>
    <div
      class="mb-4"
      style="border: 1px solid darkgray; display: flex; flex: 1"
    >
      <LeftMenuLayout>
        <!--<template v-slot:sidebar>
          <LessonSidebar :lesson-id="lessonId"></LessonSidebar>
        </template>
        -->
        <template v-slot:content>
          <router-view :key="$route.fullPath" />
        </template>
      </LeftMenuLayout>
    </div>
  </div>
</template>

<script>
import router from "@/router";

//import LessonSidebar from './LessonSidebar';

export default {
  name: "LessonLayout",
  components: {
    //    LessonSidebar
  },
  props: ["lessonId"],
  data: function () {
    return {};
  },
  methods: {
    editLesson: function () {
      router.push({ name: "edit-lesson", params: { lessonId: this.lessonId } });
    },

    clearLesson: function () {
      window.location.reload();
      this.$router.go();
      // router.push({
      //   name: "load-preview-lesson-page",
      //   params: { lessonId: this.lessonId },
      // });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
