import * as apiGetCertificates from '@/api/certificates/getCertificates'
import * as apiAddCertificate from '@/api/certificates/addCertificate'
import * as apiDeleteCertificate from '@/api/certificates/deleteCertificate'
import { Certificate } from '@/types/entities/Certificate';

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        certificates: []
    }),
    mutations: {
        gotCertificates(state, certificates) {
            console.log("Certificates", certificates);
            state.certificates = certificates
        },
    },
    actions: {
        async getCertificates({ commit }) {
            console.log('Getting Certificate List Items');

            const result = await apiGetCertificates.get();

            if (result) {
                commit('gotCertificates', result)
            }
        },

        async add({ commit }, { title }) {
            const payload = {
                title: title
            };

            return await apiAddCertificate.post(payload)
        },

        async deleteCertificate({ dispatch }, certificate: Certificate) {
            await apiDeleteCertificate.del(certificate.id)
        },
    },
    getters: {
        certificates: (state) => state.certificates,
    }
};