import * as apiGetBpmnTasks from '@/api/bpmnTasks/getBpmnTasks'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        taskOptions: [],
        templates: []
    }),
    mutations: {
        taskOptionsRefreshed(state, options) {
            state.taskOptions = options;
        },

        gotBpmnTasks(state, templates) {
            state.templates = templates;

            console.log('Got BPMN Tasks:')
            console.log(JSON.parse(JSON.stringify(state.templates)));
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getBpmnTasks({ commit }) {
            commit('isLoading', true)

            console.log('Getting BPMN Tasks');

            const result = await apiGetBpmnTasks.get();

            if (result) {
                commit('gotBpmnTasks', result)
            }

            commit('isLoading', false)
        },

        async ensureBpmnTasks({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.templates.length === 0) {
                await dispatch('getBpmnTasks')
            }

            commit('isLoading', false)
        },

        refreshTaskOptions({ commit }) {
            const options = [{
                name: 'Send Mail',
                value: 'send-mail'
            }, {
                name: 'Create Lesson',
                value: 'create-lesson'
            }];

            commit('taskOptionsRefreshed', options)
        },
    },
    getters: {
        templates: (state) => state.templates,

        taskOptions: state => state.taskOptions,
        lessonTemplates: state => state.templates.filter(x => x.source === 'lesson'),
        workflowTemplates: state => state.templates.filter(x => x.source === 'workflow')
    }
};