<template>
  <treeselect
    v-if="countries"
    ref="treeselect"
    v-model="internalValue"
    :disabled="deactivated"
    :options="selectableCountries"
    :show-count="true"
    showCountOf="LEAF_DESCENDANTS"
    :placeholder="placeholder"
    :disable-branch-nodes="true"
    :flatten-search-results="true"
    value-consists-of="LEAF_PRIORITY"
  >
  </treeselect>
</template>

<script>
import Treeselect from "vue3-treeselect";

import { mapGetters } from "vuex";

export default {
  components: { Treeselect },
  props: {
    modelValue: {
      Type: Object | Array | null,
      default: null,
    },
    placeholder: {
      Type: String | undefined,
      default: "Select a country",
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      countries: "userQueries/countries",
    }),
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
    selectableCountries() {
      return this.countries.map((country) => {
        return { id: country.id, label: country.name };
      });
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value {
  max-height: 180px;
  overflow-y: auto;
}
</style>