<template>
  <div class="mb-3">
    <label class="col-form-label">{{ element.title }}</label>
    <div class="col-12">
      <vimeo-player 
        id="player"
        v-if="isVimeo" 
        ref="player" 
        :video-url="element.content" 
        :options="vimeoOptions"
        @timeupdate="onVimeoTimeUpdate"
        @seeking="onVimeoSeeking"
        @ended="onEnded" 
      />
      <vue3-video-player v-else @play="onPlayVideo" id="player"   ref="player"  @ended="onEnded" :src="element.content"></vue3-video-player>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "Video",
  props: [
    'id',
    'namespace',
    'json',
    'outgoing'
  ],
  setup(props/*, { emit }*/) {
    const store = useStore()

    const value = ref('')
    const element = JSON.parse( props.json )

    const isVimeo = ref(element.content.includes('vimeo.com'))

    function onPlayVideo() {
    }

    function onEnded() {
      store.dispatch('forms/cacheField', { 
        path: props.namespace + '.' + props.id + '_state', 
        value: 'ended'
      });
      this.$refs.player.webkitExitFullScreen();
      window.document.getElementById("player").webkitExitFullScreen();
    }

    onMounted(() => {
    })

    return {
      value,
      element,

      isVimeo,

      onPlayVideo,
      onEnded
    };
  },

  data() {
		return {
      vimeoLastPlayTime: 0,
      vimeoPlayTimes: [0,0,0],

			vimeoOptions: {
        controls: true,
				muted: false,
        autoplay: true,
        playerWidth: undefined,
        playerHeight: undefined,
        responsive: true
			}
		}
	},

  methods: {
    onVimeoTimeUpdate(event) {
      this.vimeoPlayTimes.push(event.seconds)
      this.vimeoPlayTimes = _.takeRight(this.vimeoPlayTimes, 3);

      this.vimeoLastPlayTime = this.vimeoPlayTimes[0]
    },
    onVimeoSeeking(event, player) {
      if (event.seconds > this.vimeoLastPlayTime) {
        if (process.env.VUE_APP_MODE !== 'admin') {
          player.setCurrentTime(this.vimeoLastPlayTime)
        }
      }
      else {
        if (event.seconds) {
          this.vimeoPlayTimes = [event.seconds, event.seconds, event.seconds]
          this.vimeoLastPlayTime = event.seconds
        }
      }
    },

    refreshValue() {
      const loadedValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id)

      this.value = loadedValue || ''
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'forms/fieldCached') {
        this.refreshValue()
      }
    });
  },
  mounted() {
    this.refreshValue()
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>
