
export default {
  name: "Loading",
  props: {
    text: {
      type: String,
      required: false,
      default: "Loading",
    },
  },
};
