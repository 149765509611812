<template>
  <div class="row mb-3">
    <label class="col-sm-4 col-form-label">{{ element.title }}</label>
    <div class="col-sm-8">
      <input type="text"
        v-model="value" 
        @change="onValueChanged" 
        class="form-control" 
        :placeholder="element.placeholder">

      <button v-if="!buttonClicked && value.length > 0" @click="onNextButton" type="button" class="next-button btn btn-light px-3 mt-4">{{nextButtonTitle}}</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
//import { useStore } from 'vuex'

export default {
  name: "TextInput",
  props: [
    'id',
    'namespace',
    'json',
    'outgoing'
  ],
  setup(props/*, { emit }*/) {
    //const store = useStore()

    const buttonClicked = ref(false)

    const value = ref('')
    const element = JSON.parse( props.json )

    const nextButtonTitle = element.nextButtonTitle ?? 'Next'

    onMounted(() => {
    })

    return {
      value,
      element,

      nextButtonTitle,

      buttonClicked
    };
  },

  methods: {
    onNextButton() {
      //console.log('Selected items:', this.selectedItems)

      this.$store.dispatch('forms/cacheField', { 
        path: this.namespace + '.' + this.id, 
        value: this.value
      });

      this.$store.dispatch('forms/cacheField', { 
        path: this.namespace + '.' + this.id + '_button', 
        value: 'ok'
      });

      this.buttonClicked = true
    },
    
    onValueChanged() {
      this.$store.dispatch('forms/cacheField', { 
        path: this.namespace + '.' + this.id, 
        value: this.value 
      });
    },

    refreshValue() {
      const loadedValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id)
      this.value = loadedValue || ''

      const loadedButtonValue = this.$store.getters['forms/get'](this.namespace + '.' + this.id + '_button')
      if (loadedButtonValue === 'ok') {
        this.buttonClicked = true
      } else {
        this.buttonClicked = false
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'forms/fieldCached') {
        this.refreshValue()
      }
    });
  },
  mounted() {
    this.refreshValue()
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>
