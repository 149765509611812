<template>
  <div class="mb-3">
    <label class="col-form-label">{{ element.title }}</label>
    <div class="col-sm-8">
      <div v-for="item in items" :key="item.id" style="display: flex" class="form-group mt-4">
        <input
          type="checkbox"
          style="margin-top: 4px"
          :id="item.id"
          :value="item.name"
          v-model="selectedItems"
        />
        <label style="margin-left: 6px" :for="item.id">{{ item.name }}</label>
      </div>

      <button
        v-if="!buttonClicked"
        @click="onNextButton"
        type="button"
        class="next-button btn px-3 mt-4"
      >
        {{ nextButtonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
//import { useStore } from 'vuex'

export default {
  name: "MultipleChoice",
  components: {},
  props: ["id", "namespace", "json", "outgoing"],
  setup(props /*, { emit }*/) {
    //const store = useStore()
    const buttonClicked = ref(false);

    const value = ref("");
    const element = JSON.parse(props.json);

    const nextButtonTitle = element.nextButtonTitle ?? "Next";

    // Parse items
    const itemList = element.value;
    const itemNames = itemList.split(",");
    const parsedItems = [];
    itemNames.forEach((itemName, index) => {
      const item = { id: index, name: itemName.trim() };
      parsedItems.push(item);
    });
    const items = ref(parsedItems);

    const selectedItems = ref([]);

    onMounted(() => {});

    return {
      value,
      element,

      nextButtonTitle,

      selectedItems,

      items,

      buttonClicked,
    };
  },

  methods: {
    onNextButton() {
      console.log("Selected items:", this.selectedItems);

      this.$store.dispatch("forms/cacheField", {
        path: this.namespace + "." + this.id,
        value: this.selectedItems,
      });

      this.$store.dispatch("forms/cacheField", {
        path: this.namespace + "." + this.id + "_button",
        value: "ok",
      });

      this.buttonClicked = true;
    },

    refreshValue() {
      const loadedValue = this.$store.getters["forms/get"](
        this.namespace + "." + this.id
      );
      if (loadedValue) {
        this.selectedItems = JSON.parse(loadedValue);
      } else {
        this.selectedItems = [];
      }

      const loadedButtonValue = this.$store.getters["forms/get"](
        this.namespace + "." + this.id + "_button"
      );
      if (loadedButtonValue === "ok") {
        this.buttonClicked = true;
      } else {
        this.buttonClicked = false;
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">

.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #17312D;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3.5px;
  left: 9.5px;
  width: 6px;
  height: 14px;
  border: solid #17312D;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
