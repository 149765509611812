<template>
  <treeselect
    v-if="roles"
    ref="treeselect"
    v-model="internalValue"
    :disabled="deactivated"
    :options="selectableRoles"
    :show-count="true"
    showCountOf="LEAF_DESCENDANTS"
    :placeholder="placeholder"
    :disable-branch-nodes="true"
    :flatten-search-results="true"
    value-consists-of="LEAF_PRIORITY"
  >
  </treeselect>
</template>

<script>
import Treeselect from "vue3-treeselect";

import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { Treeselect },
  props: {
    modelValue: {
      Type: Object | Array,
      default: null,
    },
    placeholder: {
      Type: String | undefined,
      default: undefined,
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onRoleSelected"],
  methods: {
    getVenues(node) {
      if (!node.isBranch) return node.id; // Leaf id === venue id
      return node.children.flatMap((subnode) => this.getVenues(subnode));
    },
  },
  computed: {
    ...mapGetters({
      roles: "userQueries/allRoles",
    }),
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);

        if (!v) return this.$emit("onRoleSelected", null);
        const role = this.roles.find((e) => e.id === v);
        this.$emit("onRoleSelected", {
          roleId: v,
          title: role.name,
          entityType: role.entityTypeEnumValue,
        });
      },
    },
    selectableRoles() {
      return _.chain(this.roles)
        .groupBy((e) => e.entityType) // Group by Entity type
        .map((roles, entityType) => {
          return {
            id: entityType,
            label: `${entityType} roles`,
            children: roles.map((role) => {
              return {
                id: role.id,
                label: `${role.name} (${role.id})`,
              };
            }),
          };
        })
        .value();
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value {
  max-height: 180px;
  overflow-y: auto;
}
</style>