<template>
  <div>
    <label for="radioGroup" class="col-form-label mb-3">{{
      element.title
    }}</label>
    <div v-if="items.length > 0">
      <div class="form-check ml-3" v-for="(item, index) in items" :key="index">
        <input
          type="radio"
          @change="onChange"
          :value="item.name"
          :checked="selectedValue == item.name"
          class="form-check-input"
        />
        <label class="form-check-label" style="line-height: 30px">
          {{ item.name }}
        </label>
      </div>
    </div>
    <div v-else>
      <div
        class="form-check ml-3"
        v-for="(option, index) in options"
        :key="index"
      >
        <input
          type="radio"
          @change="onChange"
          :name="id"
          :value="option.data.value"
          :checked="selectedValue == option.data.value"
          class="form-check-input"
        />
        <label class="form-check-label" style="line-height: 30px">
          {{ option.data.title }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "RadioList",
  props: ["id", "namespace", "json", "outgoing"],
  setup(props) {
    const options = ref([]);
    const items = ref([]);
    const element = ref({});
    const selectedValue = ref("");

    element.value = JSON.parse(props.json);

    // Parse items
    if (element.value && element.value.value) {
      console.log("element.value", element.value.value);
      const itemList = element.value.value;
      const itemNames = itemList.split(",");
      const parsedItems = [];
      itemNames.forEach((itemName, index) => {
        const item = { id: index, name: itemName.trim() };
        parsedItems.push(item);
      });
      items.value = parsedItems;
      console.log("items", items.value);
    } else {
      const radioOptions = props.outgoing.filter(
        (x) => x.type === "RadioListOptionSequence"
      );
      options.value = radioOptions.map((x) => ({
        id: x.id,
        targetRef: x.targetRef,
        data: JSON.parse(x.json),
      }));
      options.value = options.value.sort((a, b) => a.data.order - b.data.order);
    }

    onMounted(() => {});

    return {
      element,
      items,
      options,
      selectedValue,
    };
  },
  methods: {
    onChange($event) {
      this.selectedValue = $event.target.value;

      this.$store.dispatch("forms/cacheField", {
        path: this.namespace + "." + this.id,
        value: this.selectedValue,
      });
    },

    refreshValue() {
      const loadedValue = this.$store.getters["forms/get"](
        this.namespace + "." + this.id
      );

      this.selectedValue = loadedValue || "";
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
input[type="radio"] + label {
  line-height: 20px;
}

input[type="radio"] {
  font-size: 20px;
  line-height: 20px;
}
</style>
