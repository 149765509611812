import apiClient from '@/services/axios'

export async function get() {
  var response = await apiClient.get('/folders/')
  if (response) {
    return response.data
  }

  throw new Error('Unable to get folders.')
}
