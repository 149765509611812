<template>
  <div>
    <vs-card>
      <template v-slot:header>
        <vs-row>
          <span><b>User access configuration</b></span>
          <vs-spacer />
          <div>{{ isQueryValid ? "Valid" : "Incomplete query" }}</div>
        </vs-row>
      </template>

      <div v-auto-animate v-if="userQueryInfo">
        <div v-for="(queryEntry, idx) in query.userQueryEntries" :key="queryEntry.id">
          <vs-divider border-style="dashed" v-if="idx > 0"> or </vs-divider>

          <SegmentationGroup
            v-model="query.userQueryEntries[idx]"
            :canRemoveGroup="query.userQueryEntries.length > 1 || idx > 0"
            @onDelete="deleteQueryEntry(queryEntry.id)"
          />
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>

      <div class="flex justify-center mt-2">
        <vs-tooltip text="Add new segmentation group">
          <vs-button type="flat" icon="icon-plus-circle" icon-pack="feather" @click="addNewQueryEntry" />
        </vs-tooltip>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SegmentationGroup from "./SegmentationGroup";

export default {
  components: {
    // Treeselect,
    SegmentationGroup
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    deactivateAll: {
      type: Boolean,
      required: false
    }
  },
  data() {
    console.log("Model value", this.modelValue);

    for (const queryEntry of this.modelValue.userQueryEntries) {
      if (!queryEntry.id) queryEntry.id = this.newGuid();
    }

    return {
      // query: {
      //   userQueryEntries: [
      //     {
      //       hasIdamRole: true,
      //       hasIdamRoleForEntity: true,
      //       hasPosition: true,
      //       hasTag: true,
      //       hasIsHiredAt: true,
      //       idamRoleId: null,
      //       idamRoleIdForEntity: null,
      //       idamRoleForEntityType: null,
      //       idamRoleForEntityId: null,
      //       position: null,
      //       tags: [],
      //       isHiredAtEntityType: null,
      //       isHiredAtEntityId: null,
      //     },
      //   ],
      // },
      query: this.modelValue
    };
  },
  methods: {
    addNewQueryEntry() {
      this.query.userQueryEntries.push({
        id: this.newGuid(),
        hasIdamRole: false,
        hasIdamRoleForEntity: false,
        hasPosition: false,
        hasTag: false,
        hasIsHiredAt: false,
        idamRoleId: null,
        idamRoleIdForEntity: null,
        idamRoleForEntityType: null,
        idamRoleForEntityId: null,
        position: null,
        tags: [],
        isHiredAtEntityType: null,
        isHiredAtEntityId: null
      });
    },
    deleteQueryEntry(id) {
      this.query.userQueryEntries = this.query.userQueryEntries.filter(e => e.id !== id);
    },
    newGuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    }
  },
  computed: {
    ...mapGetters({
      userQueryInfo: "userQueries/userQueryInfo"
    }),
    isQueryValid() {
      let queryValid = true;

      for (const queryEntry of this.query.userQueryEntries) {
        if (queryEntry.hasIdamRole && !queryEntry.idamRoleId) {
          console.log("Invalid idamRole", queryEntry.idamRoleId);
          queryValid = false;
          break;
        }

        if (
          queryEntry.hasIdamRoleForEntity &&
          (!queryEntry.idamRoleIdForEntity || !queryEntry.idamRoleForEntityType || !queryEntry.idamRoleForEntityId)
        ) {
          console.log(
            "Invalid idamRoleForEntity",
            queryEntry.idamRoleIdForEntity,
            queryEntry.idamRoleForEntityType,
            queryEntry.idamRoleForEntityId
          );
          queryValid = false;
          break;
        }

        if (queryEntry.hasPosition && !queryEntry.position) {
          console.log("Invalid position", queryEntry.position);
          queryValid = false;
          break;
        }

        if (queryEntry.hasTag && (!queryEntry.tags || queryEntry.tags.length === 0)) {
          console.log("Invalid tags", queryEntry.tags);
          queryValid = false;
          break;
        }

        if (queryEntry.hasIsHiredAt && (!queryEntry.isHiredAtEntityType || !queryEntry.isHiredAtEntityId)) {
          console.log("Invalid isHiredAt", queryEntry.isHiredAtEntityType, queryEntry.isHiredAtEntityId);
          queryValid = false;
          break;
        }
      }

      console.log("Emitting valid", queryValid);
      this.$emit("validQuery", queryValid);
      return queryValid;
    }
  },
  async mounted() {
    await this.$store.dispatch("userQueries/getUserQueryInfo");
  }
};
</script>

