import * as apiGetCertificate from '@/api/certificates/getCertificate'
import * as apiUpdateCertificate from '@/api/certificates/updateCertificate'
import * as apiExportDocumentToPdf from '@/api/certificates/exportDocumentToPdf'
import * as apiDownloadPreview from '@/api/certificates/apiDownloadPreview'
import * as apiSendPreviewEmail from '@/api/certificates/apiSendPreviewEmail'
import * as apiPreviewCertificate from '@/api/certificates/previewCertificate'


//import updateTemplateHtml from '@/services/updateTemplateHtml'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        template: null,
        templatePreview: null
    }),
    mutations: {
        gotCertificate(state, certificate) {
            console.log('gotCertificate:', certificate)

            state.template = certificate
        },

        sectionAdded(state, section) {
            if (!state.template.sections) {
                state.template.sections = []
            }
            state.template.sections.push(section)
        },

        previewGenerated(state, preview) {
            state.templatePreview = JSON.parse(preview.json)
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getCertificate({ commit }, { certificateId }) {
            commit('isLoading', true)

            console.log('Getting Certificate:', certificateId);

            const result = await apiGetCertificate.get(certificateId)

            if (result) {
                commit('gotCertificate', result)
            }

            commit('isLoading', false)
            return result;
        },

        async saveCertificate(_, { certificate }) {
            await apiUpdateCertificate.put(certificate.id, certificate)
        },

        async ensureCertificate({ commit, dispatch/*, getters */ }, { certificateId }) {
            commit('isLoading', true)

            /*if (!getters.certificate(certificateId)) {
                console.log('Certificate not cached. Getting from API.')*/
            await dispatch('getCertificate', { certificateId: certificateId })
            //}

            commit('isLoading', false)
        },

        async generatePreview({ commit }, { certificateId, json }) {
            commit('isLoading', true)

            console.log('Previewing Certificate');

            const apiPayload = {
                json: JSON.stringify(json)
            }

            const result = await apiPreviewCertificate.post(certificateId, apiPayload);

            if (result) {
                commit('previewGenerated', result)
            }

            commit('isLoading', false)
        },

        async downloadPreview({ commit }, { certificateId }) {
            commit('isLoading', true)


            const result = await apiDownloadPreview.post(certificateId)

            const file = new Blob([result], { type: 'application/pdf' });

            // file.name = 'certificate-preview.pdf'
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            commit('isLoading', false)
        },

        async sendPreviewEmail({ commit }, { certificateId }) {
            commit('isLoading', true)

            await apiSendPreviewEmail.post(certificateId)

            commit('isLoading', false)
        },


        async exportPreviewToPdf({ commit }, { certificate }) {
            commit('isLoading', true)


            const payload = {
                sections: certificate.sections
            }

            const result = await apiExportDocumentToPdf.post(certificate.id, payload)

            console.log("Exported PDF:", result)

            const file = new Blob([result], { type: 'application/pdf' });

            // file.name = 'test.pdf'
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            commit('isLoading', false)
        }
    },
    getters: {
        preview: (state) => {
            return state.templatePreview
        },

        activeCertificate: (state) => {
            return state.template
        },

        certificate: (state) => (certificateId) => {
            if (!state.template) {
                return null
            }

            if (state.template.id !== certificateId) {
                return null
            }

            return state.template
        }
    }
};