
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  emits: ["update:visible", "onAddLesson"],
  computed: {
    ...mapGetters({
      lessons: "lessons/lessons",
    }),
  },
  methods: {
    onRowClicked({ data }) {
      this.$emit("onAddLesson", data);
      this.$emit("update:visible", false);
    },
    refreshTable() {
      this.$store.dispatch("lessons/getLessons");
    },
  },
};
