<template>
  <div style="text-align: center">
    <h4 class="mt-5">{{ endMessage }}</h4>
    <button
      @click="onClick()"
      type="button"
      class="mb-4 btn btn-lg btn-success"
      style="padding-left: 50px; padding-right: 50px"
    >
      {{ endButtonTitle }}
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { ServiceBus } from "@/leapform/LeapFormServiceBus";

export default {
  name: "EndEvent",
  components: {},
  props: ["id", "namespace", "json", "outgoing"],
  setup(props) {
    const selectedValue = ref("");
    const endMessage = ref("Tillykke. Du har gennemført kurset'en.");
    const endButtonTitle = ref("Click to complete");

    if (props.json) {
      const element = JSON.parse(props.json);
      if (element && element.endMessage) {
        endMessage.value = element.endMessage;
      }

      if (element && element.endButtonTitle) {
        endButtonTitle.value = element.endButtonTitle;
      }
    }

    const store = useStore();

    const refreshValue = () => {
      const loadedValue = store.getters["forms/get"](
        props.namespace + "." + props.id
      );

      selectedValue.value = loadedValue || "";
    };

    const onActivate = () => {
      refreshValue();

      console.log("END EVENT - SELECTED VALUE:", selectedValue.value);

      if (selectedValue.value !== "") {
        return;
      }
    };

    const onClick = () => {
      console.log("EndEvent Button click:", props.id);

      ServiceBus.instance().emit("end-event", {
        event: "end-event",
        payload: {
          namespace: props.namespace,
          id: props.id,
        },
      });
    };

    onMounted(() => {
      console.log("EndEvent: onMounted");
      onActivate();
    });

    return {
      endMessage,
      endButtonTitle,
      selectedValue,

      refreshValue,
      onClick,
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>