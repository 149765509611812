import apiClient from '@/services/axios'

export async function post(dto) {
  var response = await apiClient.post('/folders/Rearrange', dto)
  if (response) {
    return response.data
  }

  throw new Error('Unable to rearrange folders.')
}
