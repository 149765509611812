<template>
  <div class="page">
    <div style="display: flex; justify-content: space-between; align-items: end">
      <div>
        <h2><span class="fa fa-plus-circle"></span> Create course</h2>
        <p class="mb-4">Fill out the needed fields to create your course.<br /></p>
      </div>
    </div>

    <div class="page-container">
      <form>
        <label for="title">Title</label>
        <InputText v-model="title" class="w-full" placeholder="Title" />

        <small id="titleHelp" class="form-text">E.g.: <i>My Course</i></small>
      </form>
      <Button @click="addCourse" label="Create" class="mt-4" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateCoursePage",
  computed: {
    loading() {
      return this.$store.state.courses.loading;
    }
  },
  data() {
    return {
      title: "",
      identifier: ""
    };
  },
  methods: {
    async addCourse() {
      const result = await this.$store.dispatch("courses/add", {
        title: this.title
      });

      if (!result) {
        return;
      }

      this.$router.push({
        name: "edit-course",
        params: { courseId: result.id }
      });
    }
  }
};
</script>
