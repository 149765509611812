<template>
  <div class="row mb-3">
    <label for="inputText" class="col-sm-4 col-form-label">{{ element.title }}</label>
    <div class="col-sm-8">
       <div class="form-switch" style="display:flex;padding:0;">
          <input class="form-check-input" 
            style="width: 4em;height: 2em;position: relative;margin:0;" 
            type="checkbox">
          <!--
          <label class="form-check-label" style="display: flex;align-items: center;padding-left: 0.5em;" for="flexSwitchCheckDefault">
            Show Documents Tab in Workspace
          </label>
          -->
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SwitchInput",
  props: [
    "id",
    "json",
    "outgoing"
  ],
  data() {
    const element = JSON.parse( this.json )

    return {
      element: element
    };
  },
};
</script>

<style lang="scss">
</style>
