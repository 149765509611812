import * as apiGetFolder from '@/api/folders/getFolder'
import * as apiGetFolders from '@/api/folders/getFolders'
import * as apiAddFolder from '@/api/folders/addFolder'
import * as apiUpdateFolder from '@/api/folders/updateFolder'
import * as apiDeleteFolder from '@/api/folders/deleteFolder'
import * as apiRearrangeFolders from '@/api/folders/rearrangeFolders'
import { Folder, RearrangeFoldersDto } from '@/types/entities/Folder'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        folders: [] as Folder[],
        detailedFolders: {},
    }),
    mutations: {
        gotFolders(state, folders) {
            console.log("Got folders", folders);
            state.folders = folders
        },

        gotFolder(state, folder) {
            state.detailedFolders[folder.id] = folder
        },
    },
    actions: {
        async getFolders({ commit }) {
            const result = await apiGetFolders.get();

            if (result) {
                commit('gotFolders', result)
            }
        },

        async getFolder({ commit }, { folderId }): Promise<Folder> {
            const result = await apiGetFolder.get(folderId)

            if (result) {
                commit('gotFolder', result)
            }
            return result;
        },

        async createFolder({ commit }, folder) {
            return await apiAddFolder.post(folder)
        },

        async saveFolder({ commit }, folder: Folder) {
            await apiUpdateFolder.put(folder.id, folder)
        },

        async deleteFolder({ dispatch }, folder: Folder) {
            await apiDeleteFolder.del(folder.id)
        },

        async rearrange({ dispatch }, dto: RearrangeFoldersDto) {
            await apiRearrangeFolders.post(dto)
        }
    },
    getters: {
        folders: (state) => state.folders,
    }
};