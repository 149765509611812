
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { Lesson } from "@/types/entities/Lesson";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      loading: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
    this.loadRequiredData();
  },
  computed: {
    ...mapGetters({
      lessons: "lessons/lessons",
      hasAccess: "teamMembers/hasAccess",
      canDelete: "teamMembers/canDelete",
    }),
  },
  methods: {
    async confirmDuplicate(lesson: Lesson) {
      this.$confirm.require({
        header: "Confirmation duplication",
        message: "Are you sure you want to duplicate the lesson?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await this.$store.dispatch("lessons/duplicateLesson", lesson);
            await this.loadRequiredData();
            useToast().success("Lesson duplicated.");
          } catch (err) {
            useToast().error("Could not duplicate Lesson: " + err);
          }
        },
      });
    },

    async confirmDelete(lesson: Lesson) {
      this.$confirm.require({
        header: "Confirmation deletion",
        message: "Are you sure you want to delete the lesson?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await this.$store.dispatch("lessons/deleteLesson", lesson);
            await this.loadRequiredData();
            useToast().success("Lesson deleted");
          } catch (err) {
            useToast().error("Could not delete Lesson: " + err);
          }
        },
      });
    },

    async loadRequiredData() {
      this.loading = true;
      await this.$store.dispatch("lessons/getLessons");
      await this.$store.dispatch("teamMembers/getMyRole");
      this.loading = false;
    },

    onRowClicked({ data }) {
      this.$router.push({
        name: "edit-lesson",
        params: { lessonId: data.id },
      });
    },
  },
};
