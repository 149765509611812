<template>
  <div>
    <div v-if="loading" class="w-full h-80 flex justify-center items-center">
      <Loading text="Fetching certificate" />
    </div>
    <div v-else>
      <div class="flex justify-between pt-4 px-8">
        <div>
          <p style="margin-bottom: 0.2rem; text-transform: uppercase">
            Certificate
          </p>
          <h2 class="text-4xl mb-3 font-bold tracking-tight text-gray-900">
            <span class="fa-solid fa-award"></span>
            {{ certificate?.title }}
          </h2>
        </div>
        <div class="flex justify-end">
          <div>
            <Button
              @click="saveCertificate"
              icon="fa-solid fa-floppy-disk"
              label="Save"
              :loading="saveLoading"
            />
          </div>
        </div>
      </div>

      <div class="page-container px-4">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col max-w-md">
            <label for="title">Title</label>
            <InputText
              v-model="certificate.title"
              class="w-full"
              placeholder="Title"
            />
          </div>
          <div class="flex flex-col max-w-md">
            <label for="title">Image URL</label>
            <InputText
              v-model="certificate.imageUrl"
              class="w-full"
              placeholder="Image URL"
            />
          </div>
          <div class="flex flex-col max-w-md">
            <label for="description">Description</label>
            <Textarea
              v-model="certificate.description"
              class="w-full"
              autoResize
              placeholder="Description"
            />
          </div>
          <div class="flex flex-col max-w-md">
            <label for="emailId">SendGrid dynamic template ID</label>
            <InputText
              v-model="certificate.emailTemplateId"
              class="w-full"
              placeholder="Email template id"
            />
            <small id="identifierHelp" class="form-text"
              >Edit e-mail templates and find its template ID
              <a href="https://app.sendgrid.com/" class="font-bold underline"
                >here</a
              >. Default template is
              <i>REKOM Learning Online - Diploma v1</i></small
            >
          </div>
        </div>
        <br />
        <div class="row border border-bottom-0 px-3 py-4 mx-0 mb-0">
          <div class="col-8">
            <h4 class="font-bold tracking-tight text-gray-600">
              Certificate document variables
            </h4>
            <p class="mb-0">
              The following variables can be defined in a Certificate,<br />
              and will be substituted with user data when generating the
              certificate:
            </p>

            <div class="flex items-center gap-2 mt-1">
              <Chip label="{{name}}" />
              <span>The full name of the user.</span>
            </div>
            <div class="flex items-center gap-2 mt-1">
              <Chip label="{{position}}" contentClass="my-0" />
              <span> The current performance position of the user.</span>
            </div>
            <div class="flex items-center gap-2 mt-1">
              <Chip label="{{date}}" />
              <span>The date the certificate is issued</span>
            </div>
          </div>
          <div class="col-4">
            <h4 class="font-bold tracking-tight text-gray-600">Actions</h4>
            <div class="flex flex-col gap-2">
              <div>
                <Button
                  @click="previewDocument"
                  icon="fa-solid fa-file-pdf"
                  label="Generate example PDF"
                  :loading="previewLoading"
                />
              </div>
              <div>
                <Button
                  @click="sendPreviewEmail"
                  icon="fa-solid fa-envelope-open-text"
                  label="Send example Email"
                  :loading="sendEmailLoading"
                />
              </div>
            </div>
          </div>
        </div>

        <iframe
          class="w-full mb-20"
          style="height: 95vh; border: 1px solid lightgray"
          :src="documentUrl"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "Certificate",
  data() {
    return {
      documentUrl: undefined,
      certificateId: undefined,
      certificate: undefined,
      loading: true,

      saveLoading: false,
      previewLoading: false,
      sendEmailLoading: false,
    };
  },
  async mounted() {
    this.certificateId = this.$route.params.certificateId;
    await this.loadRequiredData();
  },
  methods: {
    async loadRequiredData() {
      this.loading = true;
      this.certificate = await this.$store.dispatch(
        "certificate/getCertificate",
        {
          certificateId: this.certificateId,
        }
      );
      console.log(
        "Got certificate",
        JSON.parse(JSON.stringify(this.certificate))
      );
      this.documentUrl = `https://docs.google.com/document/d/${this.certificate.documentUrl}/edit?usp=sharing&embedded=true&rm=demo`;
      this.loading = false;
    },

    async saveCertificate() {
      try {
        this.saveLoading = true;
        await this.$store.dispatch("certificate/saveCertificate", {
          certificate: this.certificate,
        });
        useToast().success("Changes saved");
      } catch (err) {
        useToast().error(err.toString());
      } finally {
        this.saveLoading = false;
      }
    },

    async previewDocument() {
      try {
        this.previewLoading = true;
        await this.$store.dispatch("certificate/downloadPreview", {
          certificateId: this.certificateId,
        });
        useToast().success("Example document generated. Opening in new tab...");
      } catch (err) {
        useToast().error(err.toString());
      } finally {
        this.previewLoading = false;
      }
    },

    async sendPreviewEmail() {
      try {
        this.sendEmailLoading = true;
        await this.$store.dispatch("certificate/sendPreviewEmail", {
          certificateId: this.certificateId,
        });
        useToast().success("Example email sent to your email.");
      } catch (err) {
        useToast().error(err.toString());
      } finally {
        this.sendEmailLoading = false;
      }
    },
  },
};
</script>