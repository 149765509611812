import * as apiGetTeamMembers from '@/api/teamMembers/getTeamMembers'
import * as apiInviteTeamMembers from '@/api/teamMembers/inviteTeamMembers'
import * as apiRemoveTeamMember from '@/api/teamMembers/removeTeamMember'
import * as apiGetMyRole from '@/api/teamMembers/getMyRole'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        teamMemberListItems: [],
        myRole: null,
    }),
    mutations: {
        gotTeamMemberListItems(state, teamMembers) {
            state.teamMemberListItems = teamMembers
        },

        gotMyRole(state, role) {
            console.log("My role is: ", role);
            state.myRole = role
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getMyRole({ commit }) {
            const result = await apiGetMyRole.get();

            if (result) {
                commit('gotMyRole', result)
            }
            return result;
        },
        async getTeamMemberListItems({ commit }) {
            commit('isLoading', true)

            const result = await apiGetTeamMembers.get();

            if (result) {
                commit('gotTeamMemberListItems', result)
            }

            commit('isLoading', false)
        },

        async inviteTeamMembers({ commit }, { emails, role }) {
            commit('isLoading', true)

            const payload = {
                emails: emails,
                role: role
            };

            const result = await apiInviteTeamMembers.post(payload)

            commit('isLoading', false)

            return result
        },

        async removeTeamMember({ commit }, { id }) {
            commit('isLoading', true)

            const payload = {
                id: id
            };

            const result = await apiRemoveTeamMember.del(payload)

            commit('isLoading', false)

            return result
        },

        async ensureTeamMemberListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.teamMembersListItems.length === 0) {
                await dispatch('getTeamMemberListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        teamMemberListItems: (state) => state.teamMemberListItems,
        myRole: (state) => state.myRole,
        hasAccess: (state) => state.myRole === 'Administrator' || state.myRole === 'SuperAdministrator',
        canDelete: (state) => state.myRole === 'SuperAdministrator',
    }
};