import apiClient from '@/services/axios'

export async function get(invitationId) {
  var response = await apiClient.get('/teamMembers/invites/' + invitationId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Invitation.')
}
