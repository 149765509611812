import apiClient from '@/services/axios'

export async function post(courseId) {
  var response = await apiClient.post('/courses/' + courseId + '/duplicate')
  if (response) {
    return response.data
  }

  throw new Error('Unable to duplicate course.')
}
