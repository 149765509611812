import apiClient from '@/services/axios'

export async function put(folderId, payload) {
  var response = await apiClient.put('/folders/' + folderId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update folder.')
}
