<template>
  <div class="page">
    <h2 class="text-4xl mb-3 font-bold tracking-tight text-gray-900">
      <span class="fa fa-star"></span>
      Welcome
    </h2>
    <p class="mb-4 leading-6">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      aliquip.
    </p>

    <div class="row">
      <div class="left">
        <h5></h5>
      </div>
      <div class="right">
        <h5></h5>
      </div>
    </div>
    <div class="row mt-4">
      <h5></h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardPage",
  components: {},
  props: {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>
