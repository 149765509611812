<template>
  <div
    class="flex flex-grow flex-col w-screen h-screen"
    style="overflow-y: overlay"
  >
    <NavBar></NavBar>
    <LeftMenuLayout>
      <template v-slot:sidebar>
        <MainSidebar></MainSidebar>
      </template>
      <template v-slot:content>
        <router-view :key="$route.fullPath" />
      </template>
    </LeftMenuLayout>
  </div>
</template>

<script>
import MainSidebar from "./sidebars/MainSidebar";

export default {
  name: "MainLayout",
  components: {
    MainSidebar,
  },
  props: {},
  /*
  watch: {
    '$route' (to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path);
      console.log(to) 
    }
  },
  */
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
