import { createWebHistory, createRouter } from 'vue-router'

import { routeGuard } from '@/auth'

import MainLayout from '@/layouts/MainLayout.vue'

import Dashboard from '@/pages/dashboard/Dashboard.vue'

import Certificates from '@/pages/certificates/Certificates.vue'
import CreateCertificate from '@/pages/certificates/CreateCertificate.vue'
import Certificate from '@/pages/certificates/certificate/Certificate.vue'

import Courses from '@/pages/courses/Courses.vue'
import CreateCourse from '@/pages/courses/CreateCourse.vue'
import EditCourse from '@/pages/courses/EditCourse.vue'

import Lessons from '@/pages/lessons/Lessons.vue'
import CreateLesson from '@/pages/lessons/CreateLesson.vue'
import EditLesson from '@/pages/lessons/EditLesson.vue'

import Folders from '@/pages/folders/Folders.vue'

import LessonLayout from '@/pages/lessons/preview-lesson/LessonLayout.vue'
import LoadingLessonPage from '@/pages/lessons/preview-lesson/LoadingLessonPage.vue'
import DefaultLessonPage from '@/pages/lessons/preview-lesson/DefaultLessonPage.vue'

// import Developers from '@/pages/developers/Developers.vue'

import Settings from '@/pages/settings/Settings.vue'
import SettingsTeam from '@/pages/settings/team/Team.vue'

import Invite from '@/pages/invites/Invite.vue'

import NoAccount from '@/pages/common/NoAccount.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    beforeEnter: routeGuard,

    children: [
      {
        path: '',
        redirect: () => {
          return '/dashboard'
        }
      },
      {
        path: 'auth-redirect',
        redirect: () => {
          return '/dashboard'
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        props: { pageId: 'dashboard' },
        beforeEnter: routeGuard
      },

      // {
      //   path: 'content',
      //   name: 'content',
      //   component: Content,
      //   props: { pageId: 'content' },
      //   beforeEnter: routeGuard
      // },

      // {
      //   path: 'developers',
      //   name: 'developers',
      //   component: Developers,
      //   props: { pageId: 'developers' },
      //   beforeEnter: routeGuard
      // },

      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        props: { pageId: 'settings' },
        beforeEnter: routeGuard
      },
      {
        path: 'settings/team',
        name: 'settings-team',
        component: SettingsTeam,
        beforeEnter: routeGuard
      },

      {
        path: 'certificates',
        name: 'certificates',
        component: Certificates,
        beforeEnter: routeGuard
      },

      {
        path: '/certificate/:certificateId',
        name: 'certificate',
        component: Certificate,
        props: route => ({ certificateId: route.params.certificateId }),
        beforeEnter: routeGuard,
      },
      {
        path: 'certificates/create',
        name: 'create-certificate',
        component: CreateCertificate,
        beforeEnter: routeGuard
      },

      // {
      //   path: 'forms',
      //   name: 'forms',
      //   component: Forms,
      //   props: { pageId: 'forms' },
      //   beforeEnter: routeGuard
      // },
      // {
      //   path: 'forms/create',
      //   name: 'create-form',
      //   component: CreateForm,
      //   props: { pageId: 'create-form' },
      //   beforeEnter: routeGuard
      // },
      // {
      //   path: 'forms/:formId',
      //   name: 'edit-form',
      //   component: EditForm,
      //   props: { pageId: 'edit-form' },
      //   beforeEnter: routeGuard
      // },

      {
        path: 'courses',
        name: 'courses',
        component: Courses,
        props: { pageId: 'course' },
        beforeEnter: routeGuard
      },
      {
        path: 'course/create',
        name: 'create-course',
        component: CreateCourse,
        props: { pageId: 'create-course' },
        beforeEnter: routeGuard
      },

      {
        path: 'course/:courseId',
        name: 'edit-course',
        component: EditCourse,
        props: { pageId: 'edit-course' },
        beforeEnter: routeGuard
      },

      {
        path: 'lessons',
        name: 'lessons',
        component: Lessons,
        props: { pageId: 'lesson' },
        beforeEnter: routeGuard
      },
      {
        path: 'lesson/create',
        name: 'create-lesson',
        component: CreateLesson,
        props: { pageId: 'create-lesson' },
        beforeEnter: routeGuard
      },
      {
        path: 'lesson/:lessonId',
        name: 'edit-lesson',
        component: EditLesson,
        props: { pageId: 'edit-lesson' },
        beforeEnter: routeGuard
      },

      {
        path: '/lesson/preview/:lessonId',
        name: 'preview-lesson',
        component: LessonLayout,
        props: route => ({
          lessonId: route.params.lessonId
        }),
        beforeEnter: routeGuard,

        children: [
          {
            path: '',
            redirect: to => {
              const { params } = to
              console.log(params);
              return '/lesson/preview/' + params.lessonId + '/loading'
            }
          },
          {
            path: 'loading',
            name: 'load-preview-lesson-page',
            component: LoadingLessonPage,
            props: route => ({ lessonId: route.params.lessonId })
          },
          {
            path: ':pageKey',
            name: 'preview-lesson-page',
            component: DefaultLessonPage,
            props: route => ({ lessonId: route.params.lessonId, pageKey: route.params.pageKey })
          }
        ]
      },

      {
        path: 'folders',
        name: 'folders',
        component: Folders,
        props: { pageId: 'folders' },
        beforeEnter: routeGuard
      },
    ]
  },
  {
    path: '/invites/:invitationId',
    name: 'invite',
    component: Invite,
    beforeEnter: routeGuard
  },
  {
    path: '/invites',
    redirect: to => {
      console.log(to)

      const { query } = to
      return '/invites/' + query.invitationId
    }
  },

  {
    path: '/no-account',
    name: 'no-account',
    component: NoAccount,
    beforeEnter: routeGuard
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  // @ts-ignore
  routes,
});

export default router;