import apiClient from '@/services/axios'

export async function post(dto) {
  var response = await apiClient.post('/courses/Rearrange', dto)
  if (response) {
    return response.data
  }

  throw new Error('Unable to rearrange courses.')
}
