<template>
  <div class="wrapper d-flex align-items-stretch" style="flex-direction: column;">
    <NavBar></NavBar>

    <!-- Page Content  -->
    <div class="page">
      <div style="text-align: center;">
        <div>
          <img src="https://rekomfltstoragedev.blob.core.windows.net/static/rekom-logo.png" style="border-radius:50%;max-height:100px;max-width:100px;"/>
          
          <div v-if="loading">
            <div class="loading-container">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="!hasAcceptErrors">
              <!-- VALID INVITATION -->
              <div v-if="invite.valid">
                <h2>You've been invited to join {{invite.account}} on Learning Online.</h2>
                <p class="mb-4">
                  <strong>{{invite.invitedBy}} invited you to join their team.</strong> If you choose not to accept the invite, you can try the link again for up to 14 days before it expires.
                </p>
                <button @click="acceptInvitation" class="btn btn-lg btn-success mt-5">Join {{invite.account}} <i class="fas fa-arrow-right"></i></button>
              </div>

              <!-- INVALID INVITATION -->
              <div v-else>
                <h2>There're some issues with your invitation</h2>
                <p class="mb-4">
                  {{invite.message}}
                </p>
                <button v-if="invite.invitedEmail" @click="loginWithInviteAddress" class="btn btn-lg btn-success mt-5">Log in with {{invite.invitedEmail}} <i class="fas fa-arrow-right"></i></button>
              </div>
            </div>
            <div v-else>
              <h2>Could not accept invitation</h2>
                <p class="mb-4">
                  {{acceptErrors}}
                </p>
            </div>
          </div>

          <hr class="mt-5" style="border-top-color: white;margin-right: 100px;margin-left: 100px;"/>

          <span>Need help?</span> <a href="#">Click here</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { authPlugin as $auth } from '@/auth'

export default {
  components: {
  },
  setup() {
    const loading = ref(true)

    const invite = ref(undefined)
    const hasAcceptErrors = ref(false)
    const acceptErrors = ref('')

    const store = useStore()
    const route = useRoute()

    const invitationId = route.params.invitationId
    console.log('Invitation:', invitationId)

    async function loadRequiredData() {
      loading.value = true

      await store.dispatch('invitations/getInvitation', { invitationId: invitationId });

      const loadedInvitation = store.getters['invitations/invitation'](invitationId)
      console.log(loadedInvitation)

      invite.value = loadedInvitation

      loading.value = false
    }

    onMounted(async () => {
      console.log(route.query.invitationId)
      await loadRequiredData()
    })

    return {
      loading,

      invite,

      hasAcceptErrors,
      acceptErrors
    }
  },
  methods: {
    async acceptInvitation() {
      this.loading = true

      await this.$store.dispatch('invitations/acceptInvitation', { invitationId: this.invite.invitationId });

      const acceptedResult = this.$store.getters['invitations/acceptResult'](this.invite.invitationId)
      console.log(acceptedResult)

      if (acceptedResult.hasError === false)
      {
        await this.$store.dispatch('accounts/setActiveUserAccount', { accountId: acceptedResult.accountId });

        this.$router.push({ name: 'welcome'});
      } else {
        this.hasAcceptErrors = true
        this.acceptErrors = acceptedResult.error
      }

      this.loading = false
    },

    async loginWithInviteAddress() {
      const returnUrl = window.location.origin + '/invites?invitationId=' + this.invite.invitationId

      await $auth.logout({
        returnTo: returnUrl
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
