
import { mapGetters } from "vuex";
import { Folder, RearrangeFoldersDto } from "../../types/entities/Folder";
import Card from "primevue/card";
import Breadcrumb from "primevue/breadcrumb";
import EditFolderSidebar from "./components/EditFolderSidebar.vue";
import Button from "primevue/button";
import draggable from "vuedraggable";
import { Course, RearrangeCoursesDto } from "@/types/entities/Course";

export default {
  components: {
    draggable,
    Card,
    Breadcrumb,
    EditFolderSidebar,
    Button
  },
  data() {
    return {
      loading: true as boolean,
      currentPath: [{ label: "Home", id: null, icon: "fa-solid fa-folder-closed" }],
      sidebarVisible: true,

      foldersForCurrentFolder: [] as Folder[],
      coursesForCurrentFolder: [] as Course[]
    };
  },
  mounted() {
    this.loadRequiredData();
  },
  computed: {
    ...mapGetters({
      folders: "folders/folders",
      courses: "courses/courses",
      hasAccess: "teamMembers/hasAccess",
      canDelete: "teamMembers/canDelete"
    }),
    currentFolderId() {
      return this.currentPath.slice(-1)[0].id;
    },
    // contentForCurrentFolder() {
    //   // If root
    //   // if (this.currentFolderId == null) {
    //   //   filteredContent = this.folders.filter((folder: Folder) => folder.isRoot);
    //   // } else {
    //   const filteredFolders = this.folders
    //     .filter((folder: Folder) => folder.parentFolderId === this.currentFolderId)
    //     .map(e => ({ ...e, type: "folder" }));
    //   const filteredCourses = this.courses
    //     .filter((course: Course) => course.folderId === this.currentFolderId)
    //     .map(e => ({ ...e, type: "course" }));

    //   console.log("Filtered courses", JSON.parse(JSON.stringify(this.courses)), filteredCourses, this.currentFolderId);

    //   let filteredContent = [];
    //   if (filteredFolders.length === 0) {
    //     filteredContent = filteredCourses;
    //   } else {
    //     filteredContent = filteredFolders;
    //   }

    //   filteredContent.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    //   return filteredContent;
    // },
    // foldersForCurrentFolder() {
    //   const result = this.folders.filter((folder: Folder) => folder.parentFolderId === this.currentFolderId);
    //   result.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    //   return result;
    // },
    // coursesForCurrentFolder() {
    //   const result = this.courses.filter((course: Course) => course.folderId === this.currentFolderId);
    //   result.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    //   return result;
    // },
    dragOptionsFolders() {
      return {
        animation: 250,
        group: "folderContent",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    dragOptionsCourses() {
      return {
        animation: 250,
        group: "courseContent",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    popPathUntilFolderId(folderId: string) {
      while (this.currentPath.slice(-1)[0].id !== folderId) {
        this.currentPath.pop();
      }

      this.calculateFolderContent();
    },
    navigateToFolder(folder: Folder) {
      this.currentPath.push({
        label: folder.title,
        id: folder.id,
        icon: "fa-solid fa-folder"
      });

      this.calculateFolderContent();
    },
    calculateFolderContent() {
      this.foldersForCurrentFolder = this.folders.filter((folder: Folder) => folder.parentFolderId === this.currentFolderId);
      this.foldersForCurrentFolder.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      this.coursesForCurrentFolder = this.courses.filter((course: Course) => course.folderId === this.currentFolderId);
      this.coursesForCurrentFolder.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    },
    navigateToCourse(course: Course) {
      // Open in new tab
      const routeData = this.$router.resolve(`/course/${course.id}`);
      window.open(routeData.href, "_blank");
    },

    async loadRequiredData() {
      this.loading = true;
      await this.$store.dispatch("folders/getFolders");
      await this.$store.dispatch("courses/getCourses");
      await this.$store.dispatch("teamMembers/getMyRole");
      this.calculateFolderContent();
      this.loading = false;
    },
    onRearrangedFolders() {
      const newFoldersSequence = this.foldersForCurrentFolder.map((folder: Folder, index: number) => ({
        id: folder.id,
        sequenceNumber: index
      }));
      var dto: RearrangeFoldersDto = {
        newFoldersSequence
      };
      console.log("Rearranged folders", dto);

      this.$store.dispatch("folders/rearrange", dto);
    },
    onRearrangedCourses() {
      const newCoursesSequence = this.coursesForCurrentFolder.map((course: Course, index: number) => ({
        id: course.id,
        sequenceNumber: index
      }));
      var dto: RearrangeCoursesDto = {
        newCoursesSequence
      };
      console.log("Rearranged courses", event);

      this.$store.dispatch("courses/rearrange", dto);
    }
  }
};
