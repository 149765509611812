import apiClient from '@/services/axios'

export async function get(courseId) {
  var response = await apiClient.get('/courses/' + courseId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get course.')
}
