import apiClient from '@/services/axios'

export async function del(payload) {
  var response = await apiClient.delete('/teamMembers/' + payload.id)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete Team Member.')
}
