
import {
  Course,
  ExpandedCourseLessonReferenceDto,
} from "../../../types/entities/Course";
import draggable from "vuedraggable";
import AddCourseLessonDialog from "./AddCourseLessonDialog.vue";
import { Lesson } from "@/types/entities/Lesson";

export default {
  components: {
    draggable,
    AddCourseLessonDialog,
  },
  props: {
    courseLessons: {
      type: Object as () => Course,
      required: true,
    },
  },
  emits: ["update:courseLessons"],
  data() {
    return {
      loading: true,
      dialogvisible: false,
      lessons: this.courseLessons,
    };
  },
  computed: {
    lessonsEstimatedTime() {
      return this.lessons.reduce((acc, curr) => {
        return acc + curr.estimatedTime;
      }, 0);
    },
    dragOptions() {
      return {
        animation: 250,
        group: "lessons",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    async onClickEditLesson(lesson: ExpandedCourseLessonReferenceDto) {
      const routeData = this.$router.resolve(`/lesson/${lesson.lessonId}`);
      window.open(routeData.href, "_blank");

      // this.$confirm.require({
      //   header: "Leave course page?",
      //   message:
      //     "Are you sure you want to leave? Unsaved changes will be lost.",
      //   icon: "pi pi-exclamation-triangle",
      //   accept: () => {
      //     this.$router.push(`/lesson/${lesson.lessonId}`);
      //   },
      // });
    },

    onRearrangedLessons() {
      this.$emit("update:courseLessons", this.lessons);
    },

    removeLessonFromCourse(lesson: ExpandedCourseLessonReferenceDto) {
      this.lessons = this.lessons.filter(
        (e: ExpandedCourseLessonReferenceDto) => e.lessonId !== lesson.lessonId
      );
      this.$emit("update:courseLessons", this.lessons);
    },

    addLessonToCourse(lesson: Lesson) {
      const mapped: ExpandedCourseLessonReferenceDto = {
        lessonId: lesson.id,
        title: lesson.title,
        description: lesson.description,
        imageUrl: lesson.imageUrl,
        hasCooldown: false,
        cooldown: 0,
      };

      this.lessons = [...this.lessons, mapped];
      this.$emit("update:courseLessons", this.lessons);
    },
  },
};
