<template>
  <div v-if="loading" class="loading-container mx-auto">
    <Loading text="Entering REKOM Learning Online Admin" />
  </div>
  <router-view v-else :key="$route.fullPath" />
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {},
  setup() {
    const loading = ref(true);

    const store = useStore();
    const router = useRouter();

    async function loadRequiredData() {
      loading.value = true;

      await store.dispatch("accounts/ensureUserAccounts");

      const userAccount = store.getters["accounts/activeUserAccount"];
      if (userAccount == null && router.currentRoute.value.name !== "invite") {
        router.push({ name: "no-account" });
      }

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      loading,
    };
  },
};
</script>

<style>
/* Tailwind CSS */
@import "./assets/css/main.css";

@import "./assets/styles/style.css";
@import "./assets/styles/campusOnline-base.css";
@import "./assets/styles/campusOnline-card.css";
@import "./assets/styles/campusOnline-avatar.css";
@import "./assets/styles/campusOnline-table.css";

@import "./assets/styles/bpmn/bpmn-js-properties-panel.css";
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-container {
  padding-top: 80px;
}
</style>