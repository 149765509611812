export default {
    namespaced: true,
    state: () => ({
        loading: false,
        lessons: {},
    }),
    mutations: {
        gotLesson(state, lesson) {
            state.lessons[lesson.id] = lesson
        },

        pageSaved(state, payload) {
            // Ensure lesson
            if (!state.lessons[payload.lessonId]) {
                state.lessons[payload.lessonId] = {
                    pages: {}
                }
            }

            state.lessons[payload.lessonId].pages[payload.pageId] = payload.data

            console.log('Lesson page saved.')
        },

        lessonCompleted(state, lessonId) {
            state.lessons[lessonId].completed = true
            state.lessons[lessonId].completedOn = Date()
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async savePage({ commit }, { lessonId, pageId, data }) {
            commit('isLoading', true)

            console.log('Saving Lesson page...')

            const payload = {
                lessonId,
                pageId,
                data
            };

            commit('pageSaved', payload)

            commit('isLoading', false)
        },

        async complete({ commit }, { lessonId }) {
            commit('isLoading', true)

            console.log('Completing Lesson...')

            commit('lessonCompleted', lessonId)

            commit('isLoading', false)
        }
    },
    getters: {
        lesson: (state) => (lessonId) => {
            return state.lessons[lessonId]
        },

        pageForm: (state) => (lessonId, pageId) => {
            if (!state.lessons[lessonId]) {
                return null
            }

            if (!state.lessons[lessonId].pages) {
                return null
            }

            if (!state.lessons[lessonId].pages[pageId]) {
                return null
            }

            return state.lessons[lessonId].pages[pageId]
        }
    }
};