import * as apiGetUserQueryInfo from "@/api/lifeAdmin/userQuery/getUserQueryInfo";

export default {
    namespaced: true,
    state: () => ({
        userQueryInfo: null,
    }),
    mutations: {
        GOT_USER_QUERY_INFO(state, info) {
            state.userQueryInfo = info
        }
    },
    actions: {
        async getUserQueryInfo({ commit }) {
            const result = await apiGetUserQueryInfo.get();
            console.log("[USER QUERY] User query info received", result);
            if (result) {
                commit("GOT_USER_QUERY_INFO", result);
            }
        },
    },
    getters: {
        userQueryInfo: state => state.userQueryInfo,
        allRoles: state => state.userQueryInfo.allRoles,
        entityTypes: state => state.userQueryInfo.entityTypes,
        positions: state => state.userQueryInfo?.positions ?? [],
        countries: state => state.userQueryInfo.entitiesByEntityType["Country"],
        regions: state => state.userQueryInfo.entitiesByEntityType["Region"],
        venues: state => state.userQueryInfo.entitiesByEntityType["Venue"],
        entitiesByEntityType: state => state.userQueryInfo.entitiesByEntityType,
    }
};
