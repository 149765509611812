import * as apiGetLesson from '@/api/lessons/getLesson'
import * as apiGetLessons from '@/api/lessons/getLessons'
import * as apiAddLesson from '@/api/lessons/addLesson'
import * as apiUpdateLesson from '@/api/lessons/updateLesson'
import * as apiDeleteLesson from '@/api/lessons/deleteLesson'
import * as apiDuplicateLesson from '@/api/lessons/duplicateLesson'
import { Lesson } from '@/types/entities/Lesson'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        lessons: [] as Lesson[],
    }),
    mutations: {
        gotLessons(state, lessons: Lesson[]) {
            state.lessons = lessons
        },
    },
    actions: {
        async getLessons({ commit }) {
            const result = await apiGetLessons.get();

            if (result) {
                commit('gotLessons', result)
            }
        },

        async getLesson({ commit }, { lessonId }): Promise<Lesson> {
            return await apiGetLesson.get(lessonId)
        },

        async add({ commit }, { title }) {
            const payload = {
                title: title
            };

            const result = await apiAddLesson.post(payload)
            return result
        },

        async saveLesson({ commit }, lesson: Lesson) {
            await apiUpdateLesson.put(lesson.id, lesson)
        },

        async deleteLesson({ dispatch }, lesson: Lesson) {
            await apiDeleteLesson.del(lesson.id)
        },

        async duplicateLesson({ dispatch }, lesson: Lesson) {
            return await apiDuplicateLesson.post(lesson.id)
        },
    },
    getters: {
        lessons: (state) => state.lessons,
    }
};