import apiClient from '@/services/axios'

export async function del(lessonId) {
  var response = await apiClient.delete('/lessons/' + lessonId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete Lesson.')
}
