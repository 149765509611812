import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { setupAuth } from './auth'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!


import VJsoneditor from 'v-jsoneditor/src/index'

import VueClipboard from 'vue3-clipboard'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

import vueVimeoPlayer from 'vue-vimeo-player'



const app = createApp(App)

import msalPlugin from './libs/vue-msal-browser'
const msalConfig = {
    auth: {
        tenant: 'rekomgroupb2c',
        clientId: process.env.VUE_APP_AD_B2C_CLIENT_ID,
        authority: process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN,
        knownAuthorities: [process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN],
        validateAuthority: false,
        redirectUri: process.env.VUE_APP_AD_B2C_REDIRECT_URI, // It has to be configured on your Azure tenant
        scopes: ['https://rekomgroupb2c.onmicrosoft.com/rekom-life-admin-api/admin.readwrite']
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    mode: "redirect"
}
app.use(msalPlugin, msalConfig);

app.provide('onLessonEndedRouteName', 'campus')
//app.config.globalProperties.$onLessonEndedRouteName = 'campus'

app.use(router)
app.use(store)

app.use(Toast)

app.use(VJsoneditor)

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})

app.use(Vue3VideoPlayer)
app.use(vueVimeoPlayer)

import Vuesax from 'vuesax3'
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax3/dist/vuesax.css'
app.use(Vuesax, {});
import '@/assets/reset.css'
// Feather font icon
require('./assets/css/iconfont.css')

// import Treeselect styles
import "vue3-treeselect/dist/vue3-treeselect.css";

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
app.use(autoAnimatePlugin);

import filters from "./utils/filters";
app.config.globalProperties.$filters = filters;

// Global Components registration'
// @ts-ignore  
import Sidebar from './components/Sidebar'
app.component('Sidebar', Sidebar)

// @ts-ignore  
import NavBar from './components/NavBar';
app.component('NavBar', NavBar)

// @ts-ignore  
import LeftMenuLayout from './layouts/LeftMenuLayout'
app.component('LeftMenuLayout', LeftMenuLayout)

// @ts-ignore  
import LeapForm from '@/leapform/LeapForm'
app.component('LeapForm', LeapForm)

// Prime vue
import PrimeVue from 'primevue/config';
app.use(PrimeVue);
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css'; // Icons

import ConfirmationService from 'primevue/confirmationservice';
app.use(ConfirmationService);

import Button from "primevue/button";
import TreeSelect from "primevue/treeselect";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Chips from "primevue/chips";
import Chip from 'primevue/chip';
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';

app.component('Button', Button);
app.component('TreeSelect', TreeSelect);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Chips', Chips);
app.component('Chip', Chip);
app.component('Dialog', Dialog);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Skeleton', Skeleton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Divider', Divider);

// Global loading
import Loading from './components/Custom/Loading.vue'
app.component('Loading', Loading)


/*function callbackRedirect(error, appState) {
    if (error) {
        console.log(error)
    } else {
        try {
            console.log('Routing after callback')

            router.push(
                appState && appState.targetUrl
                ? appState.targetUrl
                : '/'
            )
        } catch (err) {
            console.log('Could not route:', err)
        }
    }
}*/

function callbackRedirect(error) {
    if (error) {
        console.log(error)
    } else {
        try {
            console.log('Routing after callback')

            router.push(window.location.pathname
                /*appState && appState.targetUrl
                    ? appState.targetUrl
                    : '/'*/
            )
        } catch (err) {
            console.log('Could not route:', err)
        }
    }
}

setupAuth(callbackRedirect).then((authPlugin) => {
    if (authPlugin) {
        console.log('Mounting app...')
        app.use(authPlugin).mount('#app')
    }
})
