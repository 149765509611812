<template>
  <div style="text-align: center">
    <div v-if="loading" class="loading-container mx-auto my-auto">
      <Loading text="Loading results" />
    </div>
    <div v-else>
      <h4 class="mt-3" :class="passColor">{{ passText }}!</h4>
      <h4 class="mb-1">You got a score of</h4>
      <h1 class="mb-2" :class="passColor">
        {{ lessonStats.scorePercentage }}%
      </h1>
      <h5 class="mb-3">
        A score of at least {{ this.passMinPercentage }}% is required to pass
      </h5>
      <button
        @click="onClick"
        type="button"
        class="btn btn-lg btn-success"
        style="padding-left: 50px; padding-right: 50px"
      >
        {{ passed ? "Finish" : "Try again" }}
      </button>

      <div v-if="passed" class="mt-2 cursor-pointer" @click="retake">
        <h6>Retake</h6>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <h6>
        You passed {{ this.numPassed }} out of {{ this.numTotal }} questions
      </h6>
      <div class="flow-root px-3">
        <ul role="list" class="-mb-8">
          <li v-for="(event, eventIdx) in resultsTimeline" :key="event.id">
            <div class="relative pb-8">
              <span
                v-if="eventIdx !== resultsTimeline.length - 1"
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
              <div class="relative flex space-x-3">
                <div>
                  <span
                    :class="[
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                    ]"
                  >
                    <component
                      :is="event.icon"
                      class="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div
                  class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5"
                >
                  <div>
                    <p class="text-sm text-left text-gray-500">
                      {{ event.content }}
                      <!-- <a :href="event.href" class="font-medium text-gray-900">{{
                      event.target
                    }}</a> -->
                      <span class="font-medium text-gray-900">
                        {{ event.target }}</span
                      >
                    </p>
                  </div>
                  <div
                    class="whitespace-nowrap text-right text-sm text-gray-500"
                  >
                    <!-- <time :datetime="event.datetime">{{ event.date }}</time> -->
                    <time :datetime="event.datetime"></time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import { useStore } from "vuex";
import { ServiceBus } from "@/leapform/LeapFormServiceBus";
import {
  CheckIcon,
  XMarkIcon,
  ArrowLongRightIcon,
} from "@heroicons/vue/20/solid";
export default {
  name: "LessonSummary",
  components: { ArrowLongRightIcon },
  props: ["id", "namespace", "json", "outgoing", "lessonInstanceId"],
  computed: {
    passed() {
      return this.lessonStats.scorePercentage >= this.passMinPercentage;
    },
    passColor() {
      return this.passed ? "text-green-600" : "text-red-400";
    },
    passText() {
      return this.passed ? "Passed" : "Woops";
    },
    passMinPercentage() {
      return this.loadedLessonInstance
        ? this.loadedLessonInstance.lesson.minPassingScore
        : 0;
    },
    resultsTimeline() {
      return this.lessonStats.questionsAndAnswers.map((q) => {
        return {
          id: q.questionId,
          content: q.isCorrectAnswer ? "Passed" : "Failed",
          target: q.questionText,
          href: "#",
          date: "20 Sep",
          datetime: "2020-09-20",
          icon: q.isCorrectAnswer ? CheckIcon : XMarkIcon,
          iconBackground: q.isCorrectAnswer ? "bg-green-500" : "bg-red-400",
        };
      });
    },
  },
  data() {
    return {
      loading: true,
      lessonStats: null,
      loadedLessonInstance: null,
      numPassed: 0,
      numTotal: 0,

      passPercentage: 100,
    };
  },
  methods: {
    evaluateLesson(data) {
      console.log("Evaluating lesson:", data);
      const activityTypes = ["MultipleChoice", "SortableList", "RadioList"];
      const generatedTimeline = []; // Store generated timeline items here

      const activities = data.lesson.compiledLesson.categories.flatMap(
        (category) => category.pages.flatMap((page) => page.nodes)
      );

      activities.forEach((activity) => {
        if (activityTypes.includes(activity.type)) {
          const correctAnswerFlow = activity.outgoing.find(
            (outgoing) => outgoing.type === "EqualValueSequence"
          );

          if (correctAnswerFlow) {
            const correctAnswer = correctAnswerFlow.json
              ? JSON.parse(correctAnswerFlow.json).value
              : "";

            const userAnswer =
              data.lessonInstance.data[`${activity.id}`] !== undefined
                ? data.lessonInstance.data[`${activity.id}`].startsWith("[")
                  ? JSON.parse(data.lessonInstance.data[`${activity.id}`]).join(
                      ","
                    )
                  : data.lessonInstance.data[`${activity.id}`]
                : "User did not answer";

            const questionTitle = activity.json
              ? JSON.parse(activity.json).title
              : "No title available";

            const isCorrect = userAnswer === correctAnswer;
            this.numPassed += isCorrect ? 1 : 0;
            this.numTotal += 1;
            this.passPercentage = Math.round(
              (this.numPassed / this.numTotal) * 100
            );

            // Add the result to the generated timeline
            generatedTimeline.push({
              id: activity.id,
              content: isCorrect ? "Passed" : "Failed",
              target: questionTitle,
              href: "#",
              date: "20 Sep",
              datetime: "2020-09-20",
              icon: isCorrect ? CheckIcon : XMarkIcon,
              iconBackground: isCorrect ? "bg-green-500" : "bg-red-400",
            });

            // console.log(`Question ID: ${activity.id}`);
            // console.log(`Question Title: ${questionTitle}`);
            // console.log(`User Answer: ${userAnswer}`);
            // console.log(`Correct Answer: ${correctAnswer}`);
            // console.log(`Correct: ${userAnswer === correctAnswer}`);
            // console.log("\n");
          }
        }
        this.resultsTimeline = generatedTimeline;
      });
    },
    refreshValue() {
      const store = useStore();
      const loadedValue = store.getters["forms/get"](
        this.namespace + "." + this.id
      );
      this.selectedValue = loadedValue || "";
    },
    onActivate() {
      this.refreshValue();
      console.log("END EVENT - SELECTED VALUE:", this.selectedValue);
      if (this.selectedValue !== "") {
        return;
      }
    },
    async onClick() {
      if (this.passed) {
        console.log("EndEvent Button click:", this.id);
        ServiceBus.instance().emit("end-event", {
          event: "end-event",
          payload: {
            namespace: this.namespace,
            id: this.id,
          },
        });
      } else {
        await this.retakeFailed();
      }
    },
    async loadRequiredData() {
      const courseId = this.$route.params.courseId;
      this.loading = true;
      try {
        console.log("Loading lesson stats", courseId, this.lessonInstanceId);
        // console.log("Lesson stats:", this.lessonStats);
      } catch (err) {
        console.error("Error could not load lesson stats");
      }
      this.loading = false;
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },

  async mounted() {
    this.refreshValue();
    const element = JSON.parse(this.json);
    this.loadedLessonInstance = this.$store.getters["lessons/lesson"](
      this.lessonInstanceId
    );
    await this.loadRequiredData();

    this.evaluateLesson(this.loadedLessonInstance);
    console.log("LessonSummary mounted", element);
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
</style>
