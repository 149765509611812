<template>
  <div>
    <overlay
      class="invite-users-popup"
      animate="zoom-in"
      :opened="inviteUsersPopupOpened"
      :visible="inviteUsersPopupVisible"
      :closeable="true"
      @closed="closeInvitePopup()"
    >
      <div>
        <h5>Invite new users</h5>
        <p style="font-size: 14px">
          Enter the email addresses of the users you'd like to invite, and
          choose the role they should have.
        </p>

        <hr />

        <smart-tagz
          editable
          inputPlaceholder="jane@example.com, etc."
          :on-changed="onSelectedEmailsChanged"
          :allowPaste="{ delimiter: ',' }"
          :allowDuplicates="false"
          :theme="{
            primary: '#17a2b8',
            background: '#f4f7f8',
            tagTextColor: '#fff',
          }"
        />

        <hr />

        <div v-for="role in roles" :key="role.value" class="form-check">
          <div class="col">
            <input
              class="form-check-input"
              @change="onChangeRole(role.value)"
              name="role"
              :value="role.value"
              :checked="selectedRole == role.value"
              type="radio"
            />
            <label class="form-check-label" :for="role.value">
              {{ role.title }}
            </label>
          </div>
          <div class="col description">
            <span>
              {{ role.description }}
            </span>
          </div>
        </div>

        <button
          @click="closeInvitePopup"
          type="button"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button @click="sendInvites" type="button" class="ml-2 btn btn-primary">
          <div v-if="loading">
            <div class="spinner-grow" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>Invite</div>
        </button>
      </div>
    </overlay>

    <overlay
      class="remove-user-popup"
      animate="zoom-in"
      :opened="removeUserPopupOpened"
      :visible="removeUserPopupVisible"
      @closed="closeRemoveUserPopup()"
    >
      <div>
        <h5>Remove user from team</h5>
        <p style="font-size: 14px">
          This invitation for
          <strong>{{ selectedTeamMember.email }}</strong> will be deleted, and
          they will no longer be able to sign up for REKOM Learning Online to share this
          account.
        </p>

        <button
          @click="closeRemoveUserPopup"
          type="button"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button @click="removeUser" type="button" class="ml-2 btn btn-danger">
          <div v-if="loading">
            <div class="spinner-grow" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>Remove user</div>
        </button>
      </div>
    </overlay>

    <div class="page">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        "
      >
        <div>
          <h2 class="text-4xl mb-3 font-bold tracking-tight text-gray-900">
            <span class="fa fa-users"></span>
            Team
          </h2>
          <p class="mb-4 leading-6">Add or remove members on your team.</p>
        </div>
        <div class="mb-2">
          <button
            @click="openInvitePopup()"
            type="button"
            class="btn btn-sm btn-info mt-2"
          >
            <i class="fa fa-plus-circle"></i> New member
          </button>
          <button
            @click="$router.push('/templates/create')"
            type="button"
            class="ml-2 btn btn-sm btn-info mt-2"
          >
            <i class="fa fa-file-download"></i> Export team
          </button>
        </div>
      </div>

      <hr style="color: #e3e8ee; margin: 0" />

      <div class="page-container">
        <div v-if="loading" class="loading-container">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div v-else class="vs-table">
          <table>
            <thead class="vs-table__thead">
              <tr class="vs-table__tr">
                <th class="vs-table__th">
                  <div class="vs-table__th__content">Team Member</div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">Role</div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">Last Login</div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content"></div>
                </th>
              </tr>
            </thead>
            <tbody class="vs-table__tbody">
              <tr
                v-for="teamMember in teamMembers"
                :key="teamMember.teamMemberId"
                class="vs-table__tr"
              >
                <td class="vs-table__td">
                  <div>
                    <p style="font-weight: 500; margin-bottom: 0px">
                      {{ teamMember.email }}
                      <span
                        v-if="teamMember.email === user.email"
                        style="font-size: 12px; margin-left: 10px"
                        class="badge bg-info"
                        >You</span
                      >
                    </p>
                    <p v-if="teamMember.userId" style="margin-bottom: 0px">
                      {{ teamMember.email }}
                    </p>
                  </div>
                </td>
                <td class="vs-table__td">{{ teamMember.role }}</td>
                <td class="vs-table__td">
                  <span
                    v-if="!teamMember.userId"
                    style="font-size: 12px"
                    class="badge bg-info"
                    >Invitation sent</span
                  >
                </td>
                <td class="vs-table__td">
                  <button
                    @click="openRemoveUserPopup(teamMember)"
                    type="button"
                    class="btn btn-sm btn-light"
                  >
                    Remove...
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { useStore } from "vuex";

import { useToast } from "vue-toastification";

import { SmartTagz } from "smart-tagz";
import "smart-tagz/dist/smart-tagz.css";

import Overlay from "@/components/Overlay";

import { authPlugin as $auth } from "@/auth";

//import router from "@/router";

export default {
  components: {
    SmartTagz,
    Overlay,
  },
  setup() {
    const loading = ref(true);
    const teamMembers = ref([]);

    const user = ref($auth.user);

    const removeUserPopupOpened = ref(false);
    const removeUserPopupVisible = ref(false);
    const selectedTeamMember = ref(null);

    const inviteUsersPopupOpened = ref(false);
    const inviteUsersPopupVisible = ref(false);

    const selectedRole = ref("administrator");
    const selectedEmails = ref([]);

    const roles = ref([
      {
        value: "superAdministrator",
        title: "Super administrator",
        description:
          "Only used for the main account owner. Allows the user to delete core elements",
      },
      {
        value: "administrator",
        title: "Administrator",
        description: "Best for business owners and company administrators",
      },
      {
        value: "developer",
        title: "Developer",
        description:
          "Best for developers or people primarily using the Campus Online API",
      },
      {
        value: "analyst",
        title: "Analyst",
        description:
          "Best for people who need full access to Campus Online data, but don't need to update business settings",
      },
      {
        value: "supportspecialist",
        title: "Support Specialist",
        description:
          "Best for employees who regularly handle support questions and issues",
      },
      {
        value: "viewonly",
        title: "View only",
        description:
          "Best for people who need to view Campus Online data, but don't need to make any updates",
      },
    ]);

    const store = useStore();

    const toast = useToast();

    async function loadRequiredData() {
      loading.value = true;

      try {
        await store.dispatch("teamMembers/getTeamMemberListItems");
      } catch (err) {
        toast.error("Could not load Team Members: " + err);
      }

      const loadedTeamMembers =
        store.getters["teamMembers/teamMemberListItems"];
      teamMembers.value = loadedTeamMembers;

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      loading,

      teamMembers,

      user,

      selectedTeamMember,
      removeUserPopupOpened,
      removeUserPopupVisible,

      inviteUsersPopupOpened,
      inviteUsersPopupVisible,
      selectedRole,
      roles,

      selectedEmails,

      toast,
    };
  },
  methods: {
    openRemoveUserPopup(teamMember) {
      this.selectedTeamMember = teamMember;

      this.removeUserPopupOpened = true;
      this.removeUserPopupVisible = true;
    },
    closeRemoveUserPopup() {
      this.removeUserPopupOpened = false;
      this.removeUserPopupVisible = false;
    },

    async removeUser() {
      await this.$store.dispatch("teamMembers/removeTeamMember", {
        id: this.selectedTeamMember.teamMemberId,
      });

      this.closeRemoveUserPopup();
    },

    openInvitePopup() {
      this.inviteUsersPopupOpened = true;
      this.inviteUsersPopupVisible = true;
    },
    closeInvitePopup() {
      this.inviteUsersPopupOpened = false;
      this.inviteUsersPopupVisible = false;
    },

    onChangeRole(role) {
      this.selectedRole = role;
    },

    onSelectedEmailsChanged(tags) {
      this.selectedEmails = tags;
    },

    async sendInvites() {
      try {
        this.loading = true;
        await this.$store.dispatch("teamMembers/inviteTeamMembers", {
          emails: this.selectedEmails,
          role: this.selectedRole,
        });
      } catch (err) {
        this.toast.error("Could not send invites: " + err);
      } finally {
        this.loading = false;
      }

      this.closeInvitePopup();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-check {
  display: flex;
}
.col {
  flex: 1;
}
.col.description {
  flex: 3;
}

.form-check-label {
  font-weight: 500;
  color: #000;
}

.form-check label,
.form-check span {
  font-size: 13px;
}
</style>

<style>
.tag-name[data-v-bb7ceecc] {
  font-size: 0.8rem;
}
.tag-container[data-v-bb7ceecc] {
  filter: none;
}

table.links {
  width: 100%;
}

table.links td {
  vertical-align: top;
}

table.links a {
  display: block;
}

.invite-users-popup .v-overlay-main {
  max-width: 700px;
}

.remove-user-popup .v-overlay-main {
  max-width: 700px;
}
</style>
