import apiClient from '@/services/axios'

export async function del(folderId) {
  var response = await apiClient.delete('/folders/' + folderId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete folder.')
}
