<template>
  <div class="relative" v-auto-animate>
    <div
      class="absolute"
      style="top: -24px; right: -10px"
      v-if="canRemoveGroup"
    >
      <vs-tooltip text="Remove segmentation group">
        <vs-button
          type="flat"
          icon="icon-trash"
          icon-pack="feather"
          color="danger"
          @click="$emit('onDelete')"
        />
      </vs-tooltip>
    </div>

    <segmentation-row
      class="mx-2 my-2"
      @onDelete="queryEntry.hasIdamRole = false"
      v-if="queryEntry.hasIdamRole"
      :canRemoveRow="!deactivateAll"
    >
      <template #icon>
        <ShieldIcon size="1.5x" />
      </template>
      <template #content>
        <div>Has Idam role</div>
        <div class="text-grey">for any entity</div>
      </template>
      <template #second>
        <RoleSelector
          v-model="queryEntry.idamRoleId"
          :multiple="false"
          placeholder="Select role"
        />
      </template>
    </segmentation-row>

    <segmentation-row
      class="mx-2 my-2"
      @onDelete="queryEntry.hasIdamRoleForEntity = false"
      v-if="queryEntry.hasIdamRoleForEntity"
      :canRemoveRow="!deactivateAll"
    >
      <template #icon>
        <ShieldIcon size="1.5x" />
        <PlusIcon size="0.9x" class="absolute ml-4 mb-3" />
      </template>
      <template #content>
        <div>Has Idam role</div>
        <div class="text-grey">at specific entity</div>
      </template>
      <template #first>
        <RoleSelector
          v-model="queryEntry.idamRoleIdForEntity"
          @onRoleSelected="onEntitySpecificRoleSelected"
          :multiple="false"
          placeholder="Select role"
        />
      </template>
      <template #second>
        <div v-if="!queryEntry.idamRoleForEntityType" class="text-grey">
          Select a role
        </div>
        <VenueSelector
          v-else-if="queryEntry.idamRoleForEntityType == 1"
          v-model="queryEntry.idamRoleForEntityId"
          :multiple="false"
          placeholder="Select venue"
          :deactivated="deactivateAll"
        />
        <CountrySelector
          v-else-if="queryEntry.idamRoleForEntityType == 2"
          v-model="queryEntry.idamRoleForEntityId"
          :multiple="false"
          placeholder="Select country"
          :deactivated="deactivateAll"
        />
        <RegionSelector
          v-else-if="queryEntry.idamRoleForEntityType == 3"
          v-model="queryEntry.idamRoleForEntityId"
          :multiple="false"
          placeholder="Select region"
          :deactivated="deactivateAll"
        />
      </template>
    </segmentation-row>

    <segmentation-row
      class="mx-2 my-2"
      @onDelete="queryEntry.hasPosition = false"
      v-if="queryEntry.hasPosition"
      :canRemoveRow="!deactivateAll"
    >
      <template #icon>
        <UserIcon size="1.5x" />
      </template>
      <template #content>
        <div>Has position</div>
        <div class="text-grey">through Planday</div>
      </template>
      <template #second>
        <PositionSelector
          v-model="queryEntry.position"
          :multiple="false"
          placeholder="Select position"
          :deactivated="deactivateAll"
        />
      </template>
    </segmentation-row>

    <segmentation-row
      class="mx-2 my-2"
      @onDelete="queryEntry.hasTag = false"
      v-if="queryEntry.hasTag"
      :canRemoveRow="!deactivateAll"
    >
      <template #icon>
        <TagIcon size="1.5x" />
      </template>
      <template #content>
        <div>Has tags</div>
        <div class="text-grey">user has all tags</div>
      </template>
      <template #second>
        <vs-chips
          v-model="queryEntry.tags"
          placeholder="Write a tag"
          class="w-full py-0"
        >
          <vs-chip
            :key="tag"
            @click="removeTag(tag)"
            v-for="tag in queryEntry.tags"
            class="my-1 ml-1 mr-0"
            closable
          >
            {{ tag }}
          </vs-chip>
        </vs-chips>
      </template>
    </segmentation-row>

    <segmentation-row
      class="mx-2 my-2"
      @onDelete="queryEntry.hasIsHiredAt = false"
      v-if="queryEntry.hasIsHiredAt"
      :canRemoveRow="!deactivateAll"
    >
      <template #icon>
        <BriefcaseIcon size="1.5x" />
      </template>
      <template #content>
        <div>Is hired at</div>
        <div class="text-grey">through Planday</div>
      </template>
      <template #first>
        <EntityTypeSelector
          v-model="queryEntry.isHiredAtEntityType"
          :multiple="false"
          @onEntityTypeSelected="onEntityTypeSelected"
          placeholder="Select entity type"
          :deactivated="deactivateAll"
        />
      </template>
      <template #second>
        <div v-if="!queryEntry.isHiredAtEntityType" class="text-grey">
          Select an entity type
        </div>
        <VenueSelector
          v-else-if="queryEntry.isHiredAtEntityType == 1"
          v-model="queryEntry.isHiredAtEntityId"
          :multiple="false"
          placeholder="Select venue"
          :deactivated="deactivateAll"
        />
        <CountrySelector
          v-else-if="queryEntry.isHiredAtEntityType == 2"
          v-model="queryEntry.isHiredAtEntityId"
          :multiple="false"
          placeholder="Select country"
          :deactivated="deactivateAll"
        />
        <RegionSelector
          v-else-if="queryEntry.isHiredAtEntityType == 3"
          v-model="queryEntry.isHiredAtEntityId"
          :multiple="false"
          placeholder="Select region"
          :deactivated="deactivateAll"
        />
      </template>
    </segmentation-row>

    <div
      class="flex items-center"
      v-if="
        !deactivateAll &&
        (!queryEntry.hasIdamRole ||
          !queryEntry.hasIdamRoleForEntity ||
          !queryEntry.hasPosition ||
          !queryEntry.hasTag ||
          !queryEntry.hasIsHiredAt)
      "
    >
      <div
        class="p-2 rounded-lg transition-all duration-150"
        :class="{ 'pl-4': hoveringNew }"
        @mouseenter="hoveringNew = true"
        @mouseleave="hoveringNew = false"
      >
        <div class="flex items-center pr-8">
          <div class="mr-4 flex items-center">
            <PlusIcon size="1.2x" />
            <span class="ml-2">Add new condition</span>
          </div>

          <div
            class="color-box flex-shrink-0 mx-1 cursor-pointer"
            :class="{ hide: !hoveringNew, hidden: queryEntry.hasIdamRole }"
            @click="queryEntry.hasIdamRole = true"
          >
            <ShieldIcon size="1.5x" />
          </div>

          <div
            class="color-box flex-shrink-0 mx-1 cursor-pointer"
            :class="{
              hide: !hoveringNew,
              hidden: queryEntry.hasIdamRoleForEntity,
            }"
            @click="queryEntry.hasIdamRoleForEntity = true"
          >
            <ShieldIcon size="1.5x" />
            <PlusIcon size="0.9x" class="absolute ml-4 mb-3" />
          </div>
          <div
            class="color-box flex-shrink-0 mx-1 cursor-pointer"
            :class="{ hide: !hoveringNew, hidden: queryEntry.hasPosition }"
            @click="queryEntry.hasPosition = true"
          >
            <UserIcon size="1.5x" />
          </div>
          <div
            class="color-box flex-shrink-0 mx-1 cursor-pointer"
            :class="{ hide: !hoveringNew, hidden: queryEntry.hasTag }"
            @click="queryEntry.hasTag = true"
          >
            <TagIcon size="1.5x" />
          </div>
          <div
            class="color-box flex-shrink-0 mx-1 cursor-pointer"
            :class="{ hide: !hoveringNew, hidden: queryEntry.hasIsHiredAt }"
            @click="queryEntry.hasIsHiredAt = true"
          >
            <BriefcaseIcon size="1.5x" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VenueSelector from "@/components/Custom/IdamSegmentator/Selectors/QueryVenueSelector";
import RegionSelector from "@/components/Custom/IdamSegmentator/Selectors/QueryRegionSelector";
import CountrySelector from "@/components/Custom/IdamSegmentator/Selectors/QueryCountrySelector";
import RoleSelector from "@/components/Custom/IdamSegmentator/Selectors/QueryRoleSelector";
import PositionSelector from "@/components/Custom/IdamSegmentator/Selectors/QueryPositionSelector";
import EntityTypeSelector from "@/components/Custom/IdamSegmentator/Selectors/QueryEntityTypeSelector";
import SegmentationRow from "./SegmentationRow.vue";

import {
  PlusIcon,
  TagIcon,
  ShieldIcon,
  BriefcaseIcon,
  UserIcon,
} from "vue3-feather";

export default {
  components: {
    // Treeselect,
    SegmentationRow,
    VenueSelector,
    RegionSelector,
    CountrySelector,
    RoleSelector,
    PositionSelector,
    EntityTypeSelector,

    PlusIcon,
    TagIcon,
    ShieldIcon,
    BriefcaseIcon,
    UserIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    canRemoveGroup: {
      type: Boolean,
      required: false,
    },
    deactivateAll: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    console.log("Starting value: ", this.modelValue);
    return {
      hoveringNew: false,
    };
  },
  computed: {
    queryEntry: {
      get() {
        console.log("Getting value: ", this.modelValue);
        return this.modelValue;
      },
      set(v) {
        console.log("Setting value: ", v);
        this.$emit("update:modelValue", v);
      },
    },
  },
  methods: {
    onEntityTypeSelected() {
      this.queryEntry.isHiredAtEntityId = null; // Reset the entity id
    },
    onEntitySpecificRoleSelected(role) {
      console.log("Role selected: ", role);
      this.queryEntry.idamRoleForEntityId = null; // Reset the entity id
      if (role) {
        this.queryEntry.idamRoleForEntityType = role.entityType;
      } else {
        this.queryEntry.idamRoleForEntityType = null;
      }
    },
    removeTag(tag) {
      this.queryEntry.tags = this.queryEntry.tags.filter((t) => t !== tag);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-box {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: rgba(var(--vs-primary)) !important;
  box-shadow: 0px 15px 40px -10px rgba(var(--vs-primary), 0.9);

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  opacity: 1;
  transition: opacity 0.15s;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
</style>