import apiClient from '@/services/axios'

export async function get(certificateId) {
  var response = await apiClient.get('/certificates/' + certificateId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get certificate.')
}
