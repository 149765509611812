import apiClient from '@/services/axios'

export async function put(courseId, payload) {
  var response = await apiClient.put('/courses/' + courseId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update course.')
}
