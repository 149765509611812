<template>
  <div class="page">
    <div style="display: flex; justify-content: space-between; align-items: end">
      <div>
        <h2><span class="fa fa-plus-circle"></span> Create Certificate</h2>
      </div>
    </div>

    <div class="page-container">
      <InputText v-model="title" class="w-full max-w-xs mt-2" placeholder="Title" />
      <small id="titleHelp" class="form-text">E.g.: <i>My Certificate</i></small>

      <button @click="addDocument" class="btn btn-primary mt-4">Create</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateDocumentPage",
  computed: {
    loading() {
      return this.$store.state.documentTemplates.loading;
    }
  },
  data() {
    return {
      title: ""
    };
  },
  methods: {
    async addDocument() {
      console.log("Title:", this.title);
      const result = await this.$store.dispatch("certificates/add", {
        title: this.title
      });

      if (!result) {
        return;
      }

      this.$router.push({
        name: "certificate",
        params: { certificateId: result.id }
      });
    }
  }
};
</script>