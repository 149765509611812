import apiClient from '@/services/axios'

export async function post(payload) {
  var response = await apiClient.post('/lessons/', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to add Lesson.')
}
