import apiClient from '@/services/axios'

export async function post(payload) {
  var response = await apiClient.post('/teamMembers/invites', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to invite Team Member.')
}
