<template>
  <treeselect
    v-if="venues"
    ref="treeselect"
    v-model="internalValue"
    :disabled="deactivated"
    :options="selectableVenues"
    :show-count="true"
    showCountOf="LEAF_DESCENDANTS"
    :placeholder="placeholder"
    :disable-branch-nodes="true"
    :flatten-search-results="true"
    value-consists-of="LEAF_PRIORITY"
  >
  </treeselect>
</template>

<script>
import Treeselect from "vue3-treeselect";

import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { Treeselect },
  props: {
    modelValue: {
      Type: Object | Array | null,
      default: null,
    },
    placeholder: {
      Type: String | undefined,
      default: "Select a venue",
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getVenues(node) {
      if (!node.isBranch) return node.id; // Leaf id === venue id
      return node.children.flatMap((subnode) => this.getVenues(subnode));
    },
  },
  computed: {
    ...mapGetters({
      venues: "userQueries/venues",
    }),
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
    selectableVenues() {
      return _.chain(this.venues)
        .groupBy((e) => (e.group != "" ? e.group : "No country")) // Group by country
        .map((venues, country) => {
          return {
            id: country,
            label: country,
            children: venues.map((venue) => {
              return {
                id: venue.id,
                label: venue.name,
              };
            }),
          };
        })
        .value();
    },
  },
};
</script>

<style lang="scss">
.vue-treeselect__multi-value {
  max-height: 180px;
  overflow-y: auto;
}
</style>