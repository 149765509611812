<template>
  <nav id="sidebar" :class="{ active: !isSidebarActive }">
      <div class="custom-menu">
        <button type="button" id="sidebarCollapse" @click="toggleSidebar()" class="btn btn-primary">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Toggle Menu</span>
        </button>
      </div>
      <div class="p-4">
        <h1><a href="index.html" class="logo">admin<span>.</span></a></h1>
        <ul class="list-unstyled components mb-5">
          <li v-for="option in config.options" :key="option.title" :class="activeOption == option.title ? 'active' : ''">
            <a href="#"><span class="fa mr-3" :class="option.icon"></span> {{ option.title }}</a>
          </li>
        </ul>

        <!--
        <div class="mb-5">
          <h3 class="h6 mb-3">Subscribe for newsletter</h3>
          <form action="#" class="subscribe-form">
            <div class="form-group d-flex">
              <div class="icon"><span class="icon-paper-plane"></span></div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Email Address"
              />
            </div>
          </form>
        </div>
        -->
        <div class="footer">
        </div>
      </div>
    </nav>
</template>

<script>
export default {
  name: "Sidebar",
  props: [
    'config'
  ],
  data: function () {
    return {
      isSidebarActive: true,

      activeOption: 'BPMN Editor'
      /*
      options: [
        {
          title: 'Stamoplysninger',
          icon: 'fa-home' 
        },
        {
          title: 'Boopgørelsen',
          icon: 'fa-user' 
        },
        {
          title: 'Brugere',
          icon: 'fa-briefcase' 
        },
        {
          title: 'Dokumenter',
          icon: 'fa-sticky-note' 
        },
        {
          title: 'Beskeder',
          icon: 'fa-paper-plane' 
        },
        {
          title: 'Mails',
          icon: 'fa-paper-plane' 
        },
        {
          title: 'Kvitteringer & udlæg',
          icon: 'fa-paper-plane' 
        }
      ]
      */
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
