
import { Folder, FolderTreeViewModel } from "../../../types/entities/Folder";
import Sidebar from "primevue/sidebar";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import TreeSelect from "primevue/treeselect";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default {
  components: {
    Sidebar,
    InputText,
    Textarea,
    Button,
    TreeSelect
  },
  data() {
    return {
      visible: false,
      folder: null as Folder,
      saveLoading: false,
      deleteLoading: false
    };
  },

  computed: {
    ...mapGetters({
      folders: "folders/folders",
      hasAccess: "teamMembers/hasAccess",
      canDelete: "teamMembers/canDelete"
    }),

    isNewFolder() {
      return !this.folder.id;
    },

    parentFolderNode() {
      return this.folder && this.folder.parentFolderId ? { [this.folder.parentFolderId]: true } : null;
    }
  },
  methods: {
    onNodeSelect(e) {
      this.folder.parentFolderId = Object.keys(e)[0];
    },

    show(folder: Folder) {
      console.log("FOLDER", JSON.parse(JSON.stringify(folder)));
      this.folder = JSON.parse(JSON.stringify(folder));
      this.visible = true;
    },

    showEmpty(parentFolderId: string) {
      this.folder = {
        id: null,
        title: "",
        description: "",
        imageUrl: "",
        parentFolderId: parentFolderId
      } as Folder;
      this.visible = true;
    },

    async confirmDelete(folder: Folder) {
      this.$confirm.require({
        header: "Confirmation deletion",
        message: "Are you sure you want to delete the folder?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            this.deleteLoading = true;
            await this.$store.dispatch("folders/deleteFolder", folder);
            await this.$store.dispatch("folders/getFolders");
            useToast().success("Folder deleted");
            this.$emit("update");
            this.visible = false;
          } catch (err) {
            useToast().error("Could not delete Folder: " + err);
          } finally {
            this.deleteLoading = false;
          }
        }
      });
    },

    async save() {
      // Call api and save the folder
      try {
        this.saveLoading = true;
        await this.$store.dispatch("folders/saveFolder", this.folder);
        await this.$store.dispatch("folders/getFolders"); // Refresh the folders

        useToast().success("Folder saved successfully");
        this.$emit("update");
        this.visible = false;
      } catch (err) {
        console.error(err);
        useToast().error("Error saving folder");
      } finally {
        this.saveLoading = false;
      }
    },

    async create() {
      // Call api and create the folder
      try {
        this.saveLoading = true;
        await this.$store.dispatch("folders/createFolder", this.folder);
        await this.$store.dispatch("folders/getFolders"); // Refresh the folders

        useToast().success("Folder created successfully");
        this.$emit("update");
        this.visible = false;
      } catch (err) {
        console.error(err);
        useToast().error("Error creating folder");
      } finally {
        this.saveLoading = false;
      }
    },

    // Start with null as the parentFolderId with null is root, and work our way down
    // Exclude this folder and all of its sub-folders from the tree to avoid recursion
    buildFolderNodeTree(parentFolderId: string | null = null): FolderTreeViewModel[] {
      return this.folders
        .filter((f: Folder) => f.parentFolderId === parentFolderId && f.id !== this.folder.id)
        .map((f: Folder) => {
          return {
            key: f.id,
            label: f.title,
            icon: "fa fa-folder",
            children: this.buildFolderNodeTree(f.id)
          };
        });
    }
  }
};
