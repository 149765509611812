<template>
  <div class="flex items-center">
    <div class="color-box flex-shrink-0">
      <slot name="icon"></slot>
    </div>
    <vs-col vs-w="5" class="mx-3">
      <slot name="content"></slot>
    </vs-col>
    <vs-col class="mx-2">
      <div class="flex items-center">
        <slot name="first"></slot>
      </div>
    </vs-col>
    <vs-col class="mx-2">
      <div class="flex items-center">
        <slot name="second"></slot>
      </div>
    </vs-col>
    <div v-if="canRemoveRow">
      <vs-button
        type="flat"
        icon="icon-x"
        color="grey"
        icon-pack="feather"
        @click="$emit('onDelete')"
      ></vs-button>
      <!-- <feather-icon icon="XIcon" svgClasses="w-4 h-4"></feather-icon> -->
    </div>
    <div v-else class="w-24"></div>
  </div>
</template>

<script>
export default {
  props: ["canRemoveRow"],
};
</script>

<style lang="scss" scoped>
.color-box {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: rgba(var(--vs-primary)) !important;
  box-shadow: 0px 15px 40px -10px rgba(var(--vs-primary), 0.9);

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}
</style>
