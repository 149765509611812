'use strict';

import inherits from 'inherits';

import PropertiesActivator from 'bpmn-js-properties-panel/lib/PropertiesActivator';

var is = require('bpmn-js/lib/util/ModelUtil').is;

// BPMN properties
import processProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/ProcessProps';
import eventProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/EventProps';
import linkProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/LinkProps';
import documentationProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/DocumentationProps';
import idProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/IdProps';
import nameProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/NameProps';

// camunda properties
import conditionalProps from 'bpmn-js-properties-panel/lib/provider/camunda/parts/ConditionalProps';

import elementTemplateDescriptionProps from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/DescriptionProps';
import elementTemplateChooserProps from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/ChooserProps';
import elementTemplateCustomProps from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/CustomProps';
import elementTemplateInputParametersProps from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/InputParametersProps';
import elementTemplateOutputParametersProps from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/OutputParametersProps';
const getTemplateId = require('bpmn-js-properties-panel/lib/provider/camunda/element-templates/Helper').getTemplateId;

// Input/Output
/*
import inputParameters from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/InputParametersProps';
import outputParameters  from 'bpmn-js-properties-panel/lib/provider/camunda/element-templates/parts/OutputParametersProps';
*/

// Connector
/*
import connectorDetails from './parts/ConnectorDetailProps';
import connectorInputParameters from './parts/ConnectorInputParametersProps';
import connectorOutputParameters from './parts/ConnectorOutputParametersProps';
*/

// Require your custom property entries.
import campusOnlineProps from './parts/CampusOnlineProps';

var PROCESS_KEY_HINT = 'This maps to the process definition key.';
var TASK_KEY_HINT = 'This maps to the task definition key.';

function getIdOptions(element) {

  if (is(element, 'bpmn:Participant')) {
    return { id: 'participant-id', label: 'Participant Id' };
  }

  if (is(element, 'bpmn:Process')) {
    return { description: PROCESS_KEY_HINT };
  }

  if (is(element, 'bpmn:UserTask')) {
    return { description: TASK_KEY_HINT };
  }
}

/*
function getNameOptions(element) {
  if (is(element, 'bpmn:Participant')) {
    return { id: 'participant-name', label: 'Participant Name' };
  }
}
*/

function getProcessOptions(element) {
  if (is(element, 'bpmn:Participant')) {
    return { processIdDescription: PROCESS_KEY_HINT };
  }
}

function createElementTemplateGroups(
  element,
  bpmnFactory,
  canvas,
  commandStack,
  elementTemplates,
  modeling,
  replace,
  selection,
  translate) {
var templateId = getTemplateId(element);

if (!templateId) {
  return [];
}

var descriptionGroup = elementTemplateDescriptionProps(
  element, commandStack, elementTemplates, modeling, replace, selection, translate);

var idOptions = getIdOptions(element) || {};

idOptions.id = 'element-template-element-id';

var nameOptions = { id: 'element-template-element-name' };

idProps(descriptionGroup, element, translate, idOptions);
nameProps(descriptionGroup, element, bpmnFactory, canvas, translate, nameOptions);
processProps(descriptionGroup, element, translate, getProcessOptions(element));

var elementTemplateInputParametersGroup = {
  id: 'template-inputs',
  label: translate('Input Parameters'),
  entries: []
};
elementTemplateInputParametersProps(elementTemplateInputParametersGroup, element, elementTemplates, bpmnFactory, translate);

var elementTemplateOutputParametersGroup = {
  id: 'template-outputs',
  label: translate('Output Parameters'),
  entries: []
};
elementTemplateOutputParametersProps(elementTemplateOutputParametersGroup, element, elementTemplates, bpmnFactory, translate);

var customFieldsGroups = elementTemplateCustomProps(element, elementTemplates, bpmnFactory, translate);

return [
  descriptionGroup,
  elementTemplateInputParametersGroup,
  elementTemplateOutputParametersGroup
].concat(customFieldsGroups);
}

// The general tab contains all bpmn relevant properties.
// The properties are organized in groups.
function createGeneralTabGroups(
  element, 
  bpmnFactory, 
  canvas, 
  elementRegistry, 
  elementTemplates, 
  translate
) {

  var generalGroup = {
    id: 'general',
    label: 'General',
    entries: []
  };
  idProps(generalGroup, element, translate);
  nameProps(generalGroup, element, bpmnFactory, canvas, translate);
  processProps(generalGroup, element, translate);
  elementTemplateChooserProps(generalGroup, element, elementTemplates, translate);

  var detailsGroup = {
    id: 'details',
    label: 'Details',
    entries: []
  };
  linkProps(detailsGroup, element, translate);
  eventProps(detailsGroup, element, bpmnFactory, elementRegistry, translate);
  conditionalProps(detailsGroup, element, bpmnFactory, translate);

  var documentationGroup = {
    id: 'documentation',
    label: 'Documentation',
    entries: []
  };
  documentationProps(documentationGroup, element, bpmnFactory, translate);

  return[
    generalGroup,
    detailsGroup,
    documentationGroup
  ];
}

/*
function createInputOutputTabGroups(element, bpmnFactory, elementTemplates, translate) {

  var inputParametersGroup = {
    id: 'input-parameters',
    label: translate('Input Parameters'),
    entries: []
  };

  inputParameters(inputParametersGroup, element, bpmnFactory, elementTemplates, translate);

  var outputParametersGroup = {
    id: 'output-parameters',
    label: translate('Output Parameters'),
    entries: []
  };

  outputParameters(outputParametersGroup, element, bpmnFactory, elementTemplates, translate);

  return [
    inputParametersGroup,
    outputParametersGroup
  ];
}
*/

// Create the custom campusOnline tab
function createCampusOnlineTabGroups(element, translate) {

  // Create a group called "CampusOnline Group".
  var campusOnlineGroup = {
    id: 'campusOnline-group',
    label: 'CampusOnline Group',
    entries: []
  };

  // Add the CampusOnline props to the CampusOnline group.
  campusOnlineProps(campusOnlineGroup, element, translate);

  return [
    campusOnlineGroup
  ];
}

/**
 * A properties provider for CampusOnline related properties.
 *
 * @param {BpmnFactory} bpmnFactory
 * @param {Canvas} canvas
 * @param {ElementRegistry} elementRegistry
 * @param {ElementTemplates} elementTemplates
 * @param {EventBus} eventBus
 * @param {Modeling} modeling
 * @param {Replace} replace
 * @param {Selection} selection
 * @param {Translate} translate
 */
function CampusOnlinePropertiesProvider(
  bpmnFactory,
  canvas,
  commandStack,
  elementRegistry,
  elementTemplates,
  eventBus,
  modeling,
  replace,
  selection,
  translate
) {

  PropertiesActivator.call(this, eventBus);

  this.getTabs = function(element) {

    var generalTab = {
      id: 'general',
      label: 'General',
      groups: createGeneralTabGroups(
        element, bpmnFactory, canvas, 
        elementRegistry, elementTemplates, translate
      )
    };

    var elementTemplateTab = {
      id: 'element-template',
      label: 'Template',
      groups: createElementTemplateGroups(
        element,
        bpmnFactory,
        canvas,
        commandStack,
        elementTemplates,
        modeling,
        replace,
        selection,
        translate
      )
    };

    /*
    var inputOutputTab = {
      id: 'input-output',
      label: 'Input/Output',
      groups: createInputOutputTabGroups(element, bpmnFactory, elementTemplates, translate)
    };
    */

    // The "campusOnline" tab
    var campusOnlineTab = {
      id: 'campusOnline',
      label: 'CampusOnline',
      groups: createCampusOnlineTabGroups(element, translate)
    };

    // Show general + "campusOnline" tab
    return [
      generalTab,
      elementTemplateTab,
      //inputOutputTab,
      campusOnlineTab
    ];
  };
}

CampusOnlinePropertiesProvider.$inject = [
  'bpmnFactory',
  'canvas',
  'commandStack',
  'elementRegistry',
  'elementTemplates',
  'eventBus',
  'modeling',
  'replace',
  'selection',
  'translate'
];

inherits(CampusOnlinePropertiesProvider, PropertiesActivator);

// module.exports = CampusOnlinePropertiesProvider;

export default CampusOnlinePropertiesProvider