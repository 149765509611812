import apiClient from '@/services/axios'

export async function put(certificateId, payload) {
  var response = await apiClient.put('/certificates/' + certificateId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update certificate.')
}
