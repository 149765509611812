import apiClient from '@/services/axios'

export async function post(certificateId, payload) {
  var response = await apiClient.post('/certificates/' + certificateId + '/preview', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to preview certificate.')
}
